// import React from "react";

// import counselor_pic1 from '../../assets/img/counselor_pic1.webp';
// import counselor_pic2 from '../../assets/img/counselor_pic2.webp';
// import counselor_pic3 from '../../assets/img/counselor_pic3.webp';
// import counselor_pic4 from '../../assets/img/counselor_pic4.webp';
// import counselor_pic5 from '../../assets/img/counselor_pic5.webp';

 import Brands from '../../components/Brands/Brands';





import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { base_url } from "../../api/const"; // Adjust the import path as necessary
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

const CounselorMoreDetails = () => {
	const { i18n, t } = useTranslation();
  const { slug } = useParams(); // Get the slug from the URL
  const location = useLocation();
  const { img, gerContent, engContent, spaContent } = location.state || {}; // Access passed state (img and content)
  
  let lang = i18n?.language.startsWith("en")
  ? "en"
  : i18n?.language.startsWith("es")
  ? "es"
  : i18n?.language.startsWith("de")
  ? "de"
  : "de";







  return(
	    <>
	
	
	      <div className="innercontent_wrap">
		  	<Brands headerImg img={img}/>
	        <div className="innercontent">
			<div className="auto cm_2">
		{/* <div className="heading divide">
			Kaufnebenkosten, Steuern und Gebühren
		</div> */}
		<div className="content">
		<ReactMarkdown>
			{lang == 'en' ? engContent : lang == 'es' ? spaContent : gerContent}
		</ReactMarkdown>
		</div>
	</div>
	
	        </div>
	    </div>
	    </>
	  );
};

export default CounselorMoreDetails;