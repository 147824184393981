import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import logo from "./logo.svg";
import Index from "./routes";
// import 'bootstrap/dist/css/bootstrap.min.css';

import "./assets/css/Bootstrap.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import { FavoritesProvider } from "./components/Context/FavoritesContext";
import useStore from "../src/Store/useStore";
import { base_url, property_base_url } from "./api/const";
import * as Sentry from "@sentry/react";
import CookieConsent from "./components/CookieConsent/CookieConsent";
function App() {
  const { i18n, t } = useTranslation();
  const data = useStore((state) => state.data);
  const setData = useStore((state) => state.setData);
  const estates = useStore((state) => state.estates);
  // const setEstates = useStore((state) => state.setEstates);
  const loading = useStore((state) => state.loading);
  const setLoading = useStore((state) => state.setLoading);
  const setLoadingGer = useStore((state) => state.setLoadingGer);
  const setLoadingSpa = useStore((state) => state.setLoadingSpa);
  const allData = useStore((state) => state.allData);
  // const setAllData = useStore((state) => state.setAllData);

  // const setAllDataESP = useStore((state) => state.setAllDataESP);
  // const setEstatesESP = useStore((state) => state.setEstatesESP);
  const setDataESP = useStore((state) => state.setDataESP);

  // const setAllDataGER = useStore((state) => state.setAllDataGER);
  // const setEstatesGER = useStore((state) => state.setEstatesGER);
  const setDataGER = useStore((state) => state.setDataGER);

  // const setAllDataENG = useStore((state) => state.setAllDataENG);
  // const setEstatesENG = useStore((state) => state.setEstatesENG);
  const setDataENG = useStore((state) => state.setDataENG);


  // console.log("reload!!!");
  useEffect(() => {
    // const savedVersion = localStorage.getItem('appVersion');
    
        // Clear cookies or local storage when version mismatch
        localStorage.clear(); // or clear specific keys
        document.cookie = 'yourCookieName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        // localStorage.setItem('appVersion', CURRENT_VERSION); // Store the new version
        // window.location.reload(); // Reload to refresh app
    
}, []);
  // const CryptoJS = require("crypto-js");

  // const token = "01f760ab735718c491feab1b732d06f3";
  // const secret =
  //   "47750a5bd3115a415fc2988dbc36c421c600efca69eba5cfebc660bc73178677";
  // const timestamp = Math.floor(Date.now() / 1000);
  // function createHmac(token, secret, timestamp, type, actionId) {
  //   const fields = {
  //     timestamp: timestamp,
  //     token: token,
  //     resourcetype: type,
  //     actionid: actionId,
  //   };

  //   const hmac = CryptoJS.HmacSHA256(
  //     Object.values(fields).join(""),
  //     secret
  //   ).toString(CryptoJS.enc.Base64);

  //   return hmac;
  // }

  // const resourceType = "estate";
  // const actionId = "urn:onoffice-de-ns:smart:2.5:smartml:action:read";

  // const hmac = createHmac(token, secret, timestamp, resourceType, actionId);

  // // console.log("timestamp:", timestamp);
  // // console.log("hmac:", hmac);

  // const resourceTypeImages = "estatepictures";
  // const actionIdImages = "urn:onoffice-de-ns:smart:2.5:smartml:action:get";

  // const hmacImages = createHmac(
  //   token,
  //   secret,
  //   timestamp,
  //   resourceTypeImages,
  //   actionIdImages
  // );
  // // console.log("hmac Image:", hmacImages);
  
  // const fetchAllPropertyListEstateData = useCallback(
  //   async (priceSort, timeSort) => {
  //     setLoading(true);
  //     const API_URL = "https://www.sae-mallorca.com/propertywebsite/api/proxy";
  //     const requestData = {
  //       token,
  //       request: {
  //         actions: [
  //           {
  //             actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
  //             resourceid: "",
  //             timestamp,
  //             hmac_version: "2",
  //             hmac,
  //             resourcetype: "estate",
  //             parameters: {
  //               data: [
  //                 "Id",                   // Property id(**)
  //                 "kaufpreis",            // price(**)
  //                 "lage",                 // location(**)
  //                 "objektart",            // Property kind(**)
  //                 "objekttyp",            // Property type
  //                 "objektnr_extern",      // ref id (**)
  //                 "objektnr_intern",      // no of bathroom (**)
  //                 "anzahl_badezimmer",    // no of bathroom (**)
  //                 "anzahl_zimmer",        // no of rooms (**)/
  //                 "anzahl_schlafzimmer",  // no of bedrooms (**)
  //                 "nutzflaeche",          // useable area(**)
  //                 "verfuegbar",           // Available Area
  //                 "wohnflaeche",          // Living space (**)
  //                 "gesamtflaeche",        // Total area
  //                 "ort",                  // Property short location (**)
  //                 "objekttitel",          // Property title (**)
  //                 "erstellt_am",          // created date (**)
  //                 "edi_kaufpreis",        // Purchase price (Edi-Real)
  //                 "virtualPrice",         // Fictitious price
  //                 "calculatedPrice",      // Price
  //                 "ausstatt_beschr",      // Furnishing
  //                 "objektbeschreibung",   // property description
  //                 "sonstige_angaben",     // other info
  //                 "heizungsart",          // heating type
  //                 "befeuerung",           // firing
  //                 "breitengrad",          // lat
  //                 "laengengrad",          // long
  //                 "terrasse",             // Terrace
  //                 "balkon",               // Balcony
  //                 "warmwasserEnthalten",  // hot water
  //                 "toiletten"            // toilet
  //               ],
  //               estatelanguage: "ENG",
  //               filter: {
  //                 veroeffentlichen: [{ op: "IN", val: "1" }],  // Publish
  //                 reserviert: [{ op: "=", val: 0 }],  // Reserved
  //                 verkauft: [{ op: "=", val: 0 }],  // Sold
  //               },
  //               addestatelanguage: true,
  //               listlimit: 500,
  //             },
  //           },
  //         ],
  //       },
  //     };

  //     try {
  //       const response = await axios.post(API_URL, requestData, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       });
  //       // console.log(requestData)
  //       const records =
  //         response.data.data?.response?.results[0]?.data?.records || [];
  //       const IDS = records.map((item) => parseInt(item.elements.Id));
  //       fetchAllPropertyListEstateImages(IDS, records);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setLoading(false);
  //     }
  //   },
  //   [token, hmac, timestamp]
  // );

  // const fetchAllPropertyListEstateImages = useCallback(
  //   async (ids, content) => {
  //     const API_URL = "https://www.sae-mallorca.com/propertywebsite/api/proxy";
  //     const hmacImages = createHmac(
  //       token,
  //       secret,
  //       timestamp,
  //       "estatepictures",
  //       "urn:onoffice-de-ns:smart:2.5:smartml:action:get"
  //     );

  //     const requestData = {
  //       token,
  //       request: {
  //         actions: [
  //           {
  //             actionid: actionIdImages,
  //             resourceid: "",
  //             timestamp: timestamp,
  //             hmac_version: "2",
  //             hmac: hmacImages,
  //             identifier: "",
  //             resourcetype: resourceTypeImages,
  //             parameters: {
  //               estateids: ids,
  //               categories: ["Foto"],
  //               size: "400x700",
  //               language: "",
  //             },
  //           },
  //         ],
  //       },
  //     };

  //     try {
  //       const response = await axios.post(API_URL, requestData, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       });

  //       // console.log(
  //         "Images",
  //         response.data.data?.response?.results[0]?.data.records
  //       );
  //       let DATA = content.map((estate) => {
  //         const matchingFiles =
  //           response.data.data?.response?.results[0]?.data.records.flatMap((file) =>
  //             file.elements.filter((el) => el.estateid == estate.elements.Id)
  //           );
  //         const titles = matchingFiles.map((file) => file.title);
  //         const urls = matchingFiles.map((file) => ({ url: file.url }));
  //         return {
  //           ...estate,
  //           elements: {
  //             ...estate.elements,
  //             titles,
  //             urls,
  //           },
  //         };
  //       });

  //       // console.log("DAta with Image", DATA);
  //       // setEstates(DATA);
  //       // setAllData(DATA);
  //       setData(DATA);
  //       // setEstatesENG(DATA);
  //       // setAllDataENG(DATA);
  //       setDataENG(DATA);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setLoading(false);
  //     }
  //   },
  //   [token, secret, timestamp]
  // );

// https://www.sae-mallorca.com/propertywebsite/properties/en.json

const mainToken = "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";

const fetchAllPropertyListEstateData = useCallback(
    async () => {
      setLoading(true);
      axios
      .get(`${property_base_url}properties/en.json`, {
      
        method: "GET",
        headers: {
        Authorization: `Bearer ${mainToken}`,
        },
      })
      .then((response) => {
        // console.log(response.data)
              // console.log("DAta with Image", response.data);
  
        setData(response.data);
      
        setDataENG(response.data);
        setDataGER([])
        setDataESP([])
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.message);
        setLoading(false);
      });
    },
    []
    );


  const fetchAllPropertyListEstateDataESP = useCallback(
    async () => {
      setLoadingSpa(true);
      axios
      .get(`${property_base_url}properties/es.json`, {
      
        method: "GET",
        headers: {
        Authorization: `Bearer ${mainToken}`,
        },
      })
      .then((response) => {
        // console.log(response.data)
        // console.log("DAta with Image", response.data);
       
       
        setDataESP(response.data);
        setData(response.data);
        setDataENG([]);
        setDataGER([])
        setLoadingSpa(false);
      })
      .catch((error) => {
        // console.log(error.message);
        setLoadingSpa(false);
      });
    },
    []
  );

  // const fetchAllPropertyListEstateImagesESP = useCallback(
  //   async (ids, content) => {
  //     const API_URL = "https://www.sae-mallorca.com/propertywebsite/api/proxy";
  //     const hmacImages = createHmac(
  //       token,
  //       secret,
  //       timestamp,
  //       "estatepictures",
  //       "urn:onoffice-de-ns:smart:2.5:smartml:action:get"
  //     );

  //     const requestData = {
  //       token,
  //       request: {
  //         actions: [
  //           {
  //             actionid: actionIdImages,
  //             resourceid: "",
  //             timestamp: timestamp,
  //             hmac_version: "2",
  //             hmac: hmacImages,
  //             identifier: "",
  //             resourcetype: resourceTypeImages,
  //             parameters: {
  //               estateids: ids,
  //               categories: ["Foto"],
  //               size: "400x700",
  //               language: "",
  //             },
  //           },
  //         ],
  //       },
  //     };

  //     try {
  //       const response = await axios.post(API_URL, requestData, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       });

  //       // console.log(
  //         "Images",
  //         response.data.data?.response?.results[0]?.data.records
  //       );
  //       let DATA = content.map((estate) => {
  //         const matchingFiles =
  //           response.data.data?.response?.results[0]?.data.records.flatMap((file) =>
  //             file.elements.filter((el) => el.estateid == estate.elements.Id)
  //           );
  //         const titles = matchingFiles.map((file) => file.title);
  //         const urls = matchingFiles.map((file) => ({ url: file.url }));
  //         return {
  //           ...estate,
  //           elements: {
  //             ...estate.elements,
  //             titles,
  //             urls,
  //           },
  //         };
  //       });

  //       // console.log("DAta with Image", DATA);
  //       // setEstatesESP(DATA);
  //       // setAllDataESP(DATA);
  //       setDataESP(DATA);
  //       setLoadingSpa(false);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setLoadingSpa(false);
  //     }
  //   },
  //   [token, secret, timestamp]
  // );

  const fetchAllPropertyListEstateDataGER = useCallback(
    async () => {
      setLoadingGer(true);
      axios
      .get(`${property_base_url}properties/de.json`, {
      
        method: "GET",
        headers: {
        Authorization: `Bearer ${mainToken}`,
        },
      })
      .then((response) => {
        // console.log(response.data)
              // console.log("DAta with Image", response.data);
  
        setDataGER(response.data);
      
        setDataENG([]);
        setDataESP([])
        setData(response.data);
        setLoadingGer(false);
      })
      .catch((error) => {
        // console.log(error.message);
        setLoadingGer(false);
      });
    },
    []
  );

  // const fetchAllPropertyListEstateImagesGER = useCallback(
  //   async (ids, content) => {
  //     const API_URL = "https://www.sae-mallorca.com/propertywebsite/api/proxy";
  //     const hmacImages = createHmac(
  //       token,
  //       secret,
  //       timestamp,
  //       "estatepictures",
  //       "urn:onoffice-de-ns:smart:2.5:smartml:action:get"
  //     );

  //     const requestData = {
  //       token,
  //       request: {
  //         actions: [
  //           {
  //             actionid: actionIdImages,
  //             resourceid: "",
  //             timestamp: timestamp,
  //             hmac_version: "2",
  //             hmac: hmacImages,
  //             identifier: "",
  //             resourcetype: resourceTypeImages,
  //             parameters: {
  //               estateids: ids,
  //               categories: ["Foto"],
  //               size: "400x700",
  //               language: "",
  //             },
  //           },
  //         ],
  //       },
  //     };

  //     try {
  //       const response = await axios.post(API_URL, requestData, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       });

  //       // console.log(
  //         "Images",
  //         response.data.data?.response?.results[0]?.data.records
  //       );
  //       let DATA = content.map((estate) => {
  //         const matchingFiles =
  //           response.data.data?.response?.results[0]?.data.records.flatMap((file) =>
  //             file.elements.filter((el) => el.estateid == estate.elements.Id)
  //           );
  //         const titles = matchingFiles.map((file) => file.title);
  //         const urls = matchingFiles.map((file) => ({ url: file.url }));
  //         return {
  //           ...estate,
  //           elements: {
  //             ...estate.elements,
  //             titles,
  //             urls,
  //           },
  //         };
  //       });

  //       // console.log("DAta with Image", DATA);
  //       // setEstatesGER(DATA);
  //       // setAllDataGER(DATA);
  //       setDataGER(DATA);
  //       setLoadingGer(false);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setLoadingGer(false);
  //     }
  //   },
  //   [token, secret, timestamp]
  // );
  // Sentry.captureException(new Error("This is a test error from Sentry!"));
  // React.useEffect(() => {
  //   i18n?.language.startsWith("en") ? 
  //   fetchAllPropertyListEstateData("", "") :
  //   i18n?.language.startsWith("es") ?
  //   fetchAllPropertyListEstateDataESP("", "") :
  //   fetchAllPropertyListEstateDataGER("", "");
  // }, [i18n.language]);
  return (
    <FavoritesProvider>
      <BrowserRouter>
      <CookieConsent />
        {/* <Header /> */}
        <Index />
        {/* <Footer /> */}
      </BrowserRouter>
    </FavoritesProvider>
  );
}

export default App;
