import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { base_url } from "./../../api/const";
import ReactMarkdown from "react-markdown";
import Brands from '../../components/Brands/Brands';
const ServiceGarantie = () => {
	const { i18n, t } = useTranslation();
	const [content, setContent] = useState("");
	const [loading, setLoading] = useState(true);
	const [img, setImg] = useState('')
	const link =
	i18n.language.startsWith('de')
	  ? 'https://lp.sae-mallorca.com/'
	  : 'https://lp.sae-mallorca.com/en/home/';
  
	const token =
	  "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
	useEffect(() => {
	  let lang = i18n?.language.startsWith("en")
      ? "en"
      : i18n?.language.startsWith("es")
      ? "es"
      : i18n?.language.startsWith("de")
      ? "de"
      : "de";
	  // console.log(lang);
	  axios
		.get(`${base_url}/service-gurantee?locale=${lang}&populate=*`, {
		
		  method: "GET",
		  headers: {
			Authorization: `Bearer ${token}`,
		  },
		})
		.then((response) => {
		  // console.log(response.data.data.attributes);
	  
		  setContent(response.data.data.attributes.content_field);
		  const baseUrl = "https://www.sae-mallorca.com/propertywebsite" 
		  let image = response.data.data.attributes.header_image.data.attributes.formats.large.url
		  setImg(baseUrl + image)
		  // console.log(baseUrl + image)
		  setLoading(false);
		})
		.catch((error) => {
		  // console.log(error.message);
		  setLoading(false);
		});
	}, [i18n.language]);
	if (loading)
		return (
		  <div
			style={{
			  display: "flex",
			  minHeight: 600,
			  justifyContent: "center",
			  alignItems: "center",
			  flex: 1,
			}}
		  >
			<p>{t("loadingText")}</p>;
		  </div>
		);
  return (
	
    <>
		<div className="innercontent_wrap">
			<Brands headerImg img={img}/>
			<div className="innercontent">
				<div className="auto cm_2">
				<ReactMarkdown>{content}</ReactMarkdown>
					{/* <div className="heading divide">
						Service Garantie
					</div>
					<div className="content">
						<h2>Verlassen Sie sich auf uns!</h2>
						<p>
							Um ihnen den bestmöglichen Service zu garantieren, begleiten wir sie Schritt für Schritt auf ihrem Weg zum Eigenheim auf Mallorca, und auch darüber hinaus. Service wird bei S.A.E Real Estate Mallorca groß geschrieben. Unser Service beim Immobilienkauf auf Mallorca beinhaltet Folgendes:
						</p>
						<p>
							Bei sämtlichen Behördengängen, der Beantragung der spanischen Steuernummer N.I.E, und allen notwendigen Schritten begleiten wir sie persönlich zu den jeweiligen Institutionen.
						</p>
						<p>
							Mit professionellen Partnern, deutschsprachigen Anwälten und Notaren, Banken und Handwerksbetrieben nehmen wir ihnen sämtliche Lasten ab, und geben ihnen die Möglichkeit sich auf das Wesentliche zu konzentrieren: <strong>Ihren Traum vom Eigenheim</strong>
						</p>
					</div> */}
				</div>
			</div>
		</div>
    </>
  );
};

export default ServiceGarantie;
