import React from "react";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix for the default icon issue with Webpack
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  iconSize: [25, 41], // size of the icon
  shadowSize: [41, 41] // size of the shadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const Map = ({ latitude, longitude }) => {
  const radius = 1000;
  return (
    <div className="c_map_mapbox mapboxgl-map" style={{ height: "400px", width: "370px" }}>
      <MapContainer center={[latitude, longitude]} zoom={13} style={{ height: "100%", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {/* <Marker position={[latitude, longitude]}> */}
        <Circle
        center={[latitude, longitude]}
        radius={radius}
        pathOptions={{ color: 'white', fillColor: 'red', fillOpacity: 0.1 }}
      />
          {/* <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup> */}
        {/* </Marker> */}
      </MapContainer>
    </div>
  );
};

export default Map;