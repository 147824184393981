import React, { useState, useEffect } from "react";

import about_pic1 from "../../assets/img/about_pic1.jpg";
import team_pic1 from "../../assets/img/team_pic1.webp";
import team_pic2 from "../../assets/img/team_pic2.webp";
import team_pic3 from "../../assets/img/team_pic3.webp";
import team_pic4 from "../../assets/img/team_pic4.webp";
import team_pic5 from "../../assets/img/team_pic5.webp";
import team_pic6 from "../../assets/img/team_pic6.webp";
import team_pic7 from "../../assets/img/team_pic7.webp";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { base_url } from "./../../api/const";
import { t } from "i18next";
import groupImg from '../../assets/img/SAE-©thorschoof.com-2024-26.jpg'
const OurTeam = () => {
  const { i18n } = useTranslation();
  const [contentDE, setContentDE] = useState(null);
  const [contentEN, setContentEN] = useState(null);
  const [contentES, setContentES] = useState(null);
  const [titleDE, setTitleDE] = useState(null);
  const [titleEN, setTitleEN] = useState(null);
  const [titleES, setTitleES] = useState(null);
  const [maintitle, setMaintitle] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [contentLoading, setContentLoading] = useState(false);

  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const token =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";

  const fetchContent = async () => {
    let url = "about-us?populate=*";
    try {
      setContentLoading(true);
      const response = await axios(`${base_url}/about-us?populate=*`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.data;
      // console.log(data.data.attributes.image)
      //  const Imgdata = response.data.data.attributes.localizations.data;
      const imgUrl = `https://www.sae-mallorca.com/propertywebsite${data.data.attributes.image.data.attributes.url}`;
      // Set the default language content (English)
      setImageUrl(imgUrl);
      //   // console.log(data.data.attributes.image.data.attributes.formats.large.url);

      if (data && data.data && data.data.attributes) {
        const { attributes, localizations } = data.data;
        setContentLoading(false);
        // Set the default language content (English)
        setTitleEN(data.data.attributes.title);
        setContentEN(attributes);
        // console.log(data.data.attributes.localizations);
        // Iterate over localizations and set content for other languages
        attributes.localizations.data?.forEach((loc) => {
          switch (loc.attributes.locale) {
            case "de":
              setContentDE(loc.attributes);
              setTitleDE(loc.attributes.title);
              // console.log(loc.attributes);
              break;
            case "es":
              setContentES(loc.attributes);
              setTitleES(loc.attributes.title);
              break;
            default:
              break;
          }
        });
      }
      setContentLoading(false);
    } catch (error) {
      console.error("Error fetching content:", error);
      setContentLoading(false);
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  useEffect(() => {
    let lang = i18n?.language.startsWith("en")
    ? "en"
    : i18n?.language.startsWith("es")
    ? "es"
    : i18n?.language.startsWith("de")
    ? "de"
    : "de";
    // console.log(lang);
    axios
      .get(`${base_url}/teams?locale=${lang}&populate=*&sort=id:asc`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setTeamData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.message);
        setLoading(false);
      });
  }, [i18n.language]);

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          minHeight: 600,
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <p>{t("loadingText")}</p>;
      </div>
    );

  const currentLanguage = i18n?.language.startsWith("en")
  ? "en"
  : i18n?.language.startsWith("es")
  ? "es"
  : i18n?.language.startsWith("de")
  ? "de"
  : "de";
  const getContentByLanguage = () => {
    switch (currentLanguage) {
      case "de":
        return contentDE;
      case "es":
        return contentES;
      case "en":
      default:
        return contentEN;
    }
  };

  const content = getContentByLanguage();

  const getTitlebyLanguage = () => {
    switch (currentLanguage) {
      case "de":
        return titleDE;
      case "es":
        return titleES;
      case "en":
      default:
        return titleEN;
    }
  };

  const title = getTitlebyLanguage();

  return (
    <>
      <div className="innercontent_wrap">
        <div className="innercontent">
          <div className="e_data_ids">
            <div className="auto cm_2">
              <div className="heading divide">
                <h1>{title}</h1>
              </div>
              <div className="content" style={{ marginTop: 10,}}>
                <img 
                src={imageUrl}
                // src={groupImg} 
                style={{ width: "100%",  resize:'contain' }} alt="team" />
                <p>
                  {/* <img src={about_pic1} alt="team"/> */}
                  {/* Bei S.A.E Real Estate Mallorca erwartet sie ein junges,
                  hochmotiviertes und dynamisches Team mit multikulturellem
                  Background und langjähriger Erfahrung im Immobiliensektor
                  Mallorcas, maßgeschneidertem Service und internationaler
                  Sprachkompetenz. So finden sie bei uns immer den richtigen
                  Ansprechpartner zur Korrespondenz mit ausländischen
                  Eigentümern und Interessenten und werden von der ersten
                  Kontaktaufnahme bis zum Notar und darüber hinaus, in allen
                  Belangen kompetent begleitet. */}
                  {content?.description?.map((paragraph, index) => (
                    <p key={index}>{paragraph.children[0]?.text}</p>
                  ))}
                </p>
                {/*
							<p>
								Als langjährige Sachverständige für Immobilienbewertung, verfügen wir nicht nur über alle fachlichen Kernkompetenzen, welche der Immobilienmarkt von heute fordert, sondern auch über die nötige Erfahrung zur Einschätzung rentabler Wertanlagen sowie zwei Augen für alle relevanten Details.
							</p>
							<p>
								Im Bereich junger Medien und Social Media Marketing gelten wir als Pioniere auf Mallorca und bieten hiermit sowohl Käufern als auch Verkäufern gleichzeitig einen Mehrwert.
							</p>
							<p>
								S.A.E ist der Fachansprechpartner für die Suche ihrer Traumimmobilie auf Mallorca. Durch unser langjähriges, stetig wachsendes Netzwerk bestehend aus Fachanwälten, Notaren, Handwerksbetrieben und allen relevanten Dienstleistern im Rahmen des Immobilienkaufs sind wir in der Lage, unseren Kunden zugriff auf ALLE zum Verkauf stehenden Immobilien auf der Sonneninsel Mallorca zu bieten. Alles aus einer Hand, von A-Z.
							</p> */}
              </div>
            </div>

            <div className="auto cm_16">
              <div className="divide heading txt_1">{t("ourTeam")}</div>
              <div
                className="auto row wrap spaced cols_2"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {teamData.map((member) => (
                  <TeamMember key={member.id} member={member.attributes} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const TeamMember = ({ member }) => {
  const { i18n } = useTranslation();

  // Select the localized version of the member
  const localizedData =
    member.localizations.data.find(
      (loc) => loc.attributes.locale == i18n.language
    ) || member;

  const isLocalized = localizedData.attributes;

  const name = isLocalized ? localizedData.attributes.name : localizedData.name;

  const designation = isLocalized
    ? localizedData.attributes.designation
    : localizedData.designation;

  const qualification = isLocalized
    ? localizedData.attributes.qualification
    : localizedData.qualification;

  const email = isLocalized
    ? localizedData.attributes.email
    : localizedData.email;

  // console.log(name, email, qualification);

  return (
    <div className="box" style={{ marginBottom: 10,
      // height: "500px",
      // overflow: "hidden " 
      }}>
      <img
        className=""
        alt="juan-nieto.jpg"
        title="juan-nieto.jpg"
        // style={{ height: 400}}
        src={`https://www.sae-mallorca.com/propertywebsite${member.image.data.attributes.url}`}
      />
      <div className="content">
       {name && <div className="heading txt_2">{name}</div> }
        <p>
          <strong>{designation}</strong>
          {qualification === null ? null : <strong>{qualification} </strong>}
         { email ?  <>E-Mail: <a href={`mailto:${email}`}>{email}</a> </>: null }
        </p>
        {/* <p>
                        E-Mail:{" "}
                        <a href={`mailto:${localizedData.email}`}>{localizedData.email}</a>
                      </p> */}
      </div>
    </div>
  );
};
export default OurTeam;
