import React from "react";
import { Link } from "react-router-dom";
import icon1 from "../../assets/img/icon1.png";
import icon2 from "../../assets/img/icon2.png";
import icon3 from "../../assets/img/icon3.png";
import icon4 from "../../assets/img/icon4.png";

import addpic1 from "../../assets/img/addpic1.jpg";
import addpic2 from "../../assets/img/addpic2.jpg";
import addpic3 from "../../assets/img/addpic3.jpg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
const AddProperty = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const handlePropertyTypeChange = (value) => {
    setTimeout(() =>{
    history.push({
      pathname: "/propertysearch",
      state: {
        refNumber: "",
        maximumPrice: "",
        minimumPrice: "",
        filteredLocations: [],
        typeOfProperty: [value],
        numberOfBed: "",
      },
    });
  }, 1000)
  };

  // const propertyTypeMappings = {
  //   "haus": t("house"),
  //   "wohnung": t("apartment"),
  //   "grundstueck": t("land"),
  //   "gastgewerbe": t("commercial"),
  //   "einzelhandel": t("retail"),
  //   "neubau": t("newBuild"),
  //   "sonstige": t("other")
  // };
  return (
    <section className="AddProperty_offer" style={{marginBottom:50}}>
    <div className="auto cm_3">
      <div className="heading txt_1 divide">
        <h1>{t('maklergeprufteImmobilien')}</h1>
      </div>
      <div className="content txt_3">
        <p>{t('saeRealEstateDescription')}</p>
      </div>
      <div className="auto row spaced grow cols_4">
        <div className="box">
          <img className="sprites" alt="icon crown" src={icon1} />
          <div className="heading txt_3">{t('honesty')}</div>
          <div className="content">
            <p>{t('honestyDescription')}</p>
          </div>
        </div>
        <div className="box">
          <img className="sprites" alt="icon eye" src={icon2} />
          <div className="heading txt_3">{t('creativity')}</div>
          <div className="content">
            <p>{t('creativityDescription')}</p>
          </div>
        </div>
        <div className="box">
          <img className="sprites" alt="icon handshake" src={icon3} />
          <div className="heading txt_3">{t('value')}</div>
          <div className="content">
            <p>{t('valueDescription')}</p>
          </div>
        </div>
        <div className="box">
          <img className="sprites" alt="icon team" src={icon4} />
          <div className="heading txt_3">{t('team')}</div>
          <div className="content">
            <p>{t('teamDescription')}</p>
          </div>
        </div>
      </div>
    </div>
  
    <div className="cm_4">
      <div className="row spaced grow cols_3">
        <a
          className="box"
          onClick={() => handlePropertyTypeChange("haus")}
        >
          <div className="pic adj_image_width">
            <img src={addpic1} alt="" title="" />
          </div>
          <div className="header txt_3">{t('houseVillaMallorca')}</div>
        </a>
        <a
          className="box"
          onClick={() => handlePropertyTypeChange("neubau")}
        >
          <div className="pic adj_image_width">
            <img src={addpic2} alt="" title="" />
          </div>
          <div className="header txt_3">{t('newBuildMallorca')}</div>
        </a>
        <a
          className="box"
          onClick={() => handlePropertyTypeChange("wohnung")}
        >
          <div className="pic adj_image_width">
            <img src={addpic3} alt="" title="" />
          </div>
          <div className="header txt_3">{t('apartmentMallorca')}</div>
        </a>
      </div>
    </div>
  </section>
  );
};

export default AddProperty;
