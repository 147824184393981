import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { base_url } from "./../../api/const";

import partner_pic1 from '../../assets/img/partner_pic1.webp';
import partner_pic2 from '../../assets/img/partner_pic2.webp';


const Partner = () => {
	const { i18n, t } = useTranslation();
	const img_url = "https://www.sae-mallorca.com/propertywebsite"
	const [mail, setMail] = useState("");
  const [vatId, setVatId] = useState("");
  const [content, setContent] = useState([]);

  const [loading, setLoading] = useState(true);
  const token =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
  useEffect(() => {
    let lang = i18n?.language.startsWith("en")
    ? "en"
    : i18n?.language.startsWith("es")
    ? "es"
    : i18n?.language.startsWith("de")
    ? "de"
    : "de";
    // console.log(lang);
    axios
      .get(`${base_url}/partners?populate=*`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data.data[0].attributes.img.data[0].attributes);
        setContent(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.message);
        setLoading(false);
      });
  }, [i18n.language]);
  if (loading)
    return (
      <div
        style={{
          display: "flex",
          minHeight: 600,
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <p>{t("loadingText")}</p>
      </div>
    );
  return (
    <>
		<div className="innercontent_wrap">
			<div className="innercontent partner_info">
				<div className="auto cm_18">
					<div className="divide heading txt_1">
						{t('ourPartners')}
					</div>
					<div className="auto row wrap spaced cols_2">
						
						{content.map((item, i)=> {
							let url = item.attributes.img.data[0].attributes.url
							return(
							<div className="box" key={i}>
							<a className="box"><img className="" alt="" src={img_url + url}/>
							
								<div className="heading" style={{"fontSize": "27px",
	"paddingTop": "4px",
	"marginBottom": "24px",
	"lineHeight": "28px",}}>
									{item.attributes.title}
								</div>
								<div >
									<p>
										<a href={`https://${item.attributes.url}`} style={{ textDecoration: 'underline', }}>{item.attributes.url}</a>
									</p>
								</div>
						
							</a>
						</div>
						)})}
						
						
					</div>
				</div>
			</div>
		</div>
    </>
  );
};

export default Partner;
