import axios from "axios";

const client = axios.create({
  baseURL: "https://nodeserver.mydevfactory.com:6018",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export default client;
