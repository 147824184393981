import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import axios from "axios";
import { base_url } from "./../../api/const";
import VideoPlayer from "../../components/VideoPlayer";

const Counselor = () => {
  const { i18n, t } = useTranslation();
  const [videoData, setVideoData] = useState([]);
  const img_url = "https://www.sae-mallorca.com/propertywebsite";
  const [content, setContent] = useState([]);

  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(true);
  const getVideoIdFromLink = (link) => {
    const url = new URL(link);
    return url.pathname === "/watch"
      ? url.searchParams.get("v")
      : url.pathname.slice(1);
  };

  const token =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
  const fetchVideoData = async () => {
    const language = i18n?.language.startsWith("en")
    ? "en"
    : i18n?.language.startsWith("es")
    ? "es"
    : i18n?.language.startsWith("de")
    ? "de"
    : "de";
    try {
      const response = await axios(`${base_url}/videos?populate=*&pagination[pageSize]=50`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data.data);
      const filteredData = response.data.data
        .filter((video) => video.attributes.menu === "Video Guide")
        .map((video) => {
          // Check if the locale of the video matches the selected language
          if (video.attributes.locale === language) {
            return video;
          } else {
            // If not, find the corresponding localization
            const localizedVideo = video.attributes.localizations.data.find(
              (loc) => loc.attributes.locale === language
            );
            return localizedVideo || video; // If not found, fallback to default video
          }
        });
      setVideoData(filteredData);
      // console.log(filteredData);
    } catch (error) {
      console.error("Error fetching video data", error);
    }
  };
  useEffect(() => {
    fetchVideoData();
  }, [i18n.language]);
  useEffect(() => {
    let lang = i18n?.language.startsWith("en")
      ? "en"
      : i18n?.language.startsWith("es")
      ? "es"
      : i18n?.language.startsWith("de")
      ? "de"
      : "de";
    // console.log(lang);
    axios
      .get(`${base_url}/blogs?locale=${lang}&populate=*`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setContent(response.data.data);
        setLoad(false);
      })
      .catch((error) => {
        // console.log(error.message);
        setLoad(false);
      });
  }, [i18n.language]);
  const currentLanguage =  i18n.language.startsWith('en') ? "en" :  i18n?.language.startsWith('es') ? "es" : "de";

  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/(^-|-$)/g, ""); // Remove leading or trailing hyphens
  };
  if (loading && load)
    return (
      <div
        style={{
          display: "flex",
          minHeight: 600,
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <p>{t("loadingText")}</p>;
      </div>
    );
  return (
    <>
      <div className="innercontent_wrap">
        <div className="innercontent">
          <div className="e_data_ids">
            <div className="auto cm_17">
              <div className="divide heading txt_1">{t("videoGuide")}</div>
              <div
                className="auto row spaced cols_2"
                style={{ direction: "flex", flexWrap: "wrap" }}
              >
                {videoData.map((video, index) => {
                  const videoAttributes = video.attributes;
                  const youtubeData = JSON.parse(videoAttributes.youtube);

                  // Default title to youtubeData.title
                  let title = youtubeData.title;

                  // Check if localizations and data exist
                  if (
                    currentLanguage !== "en" &&
                    videoAttributes.localizations?.data
                  ) {
                    // Find the localization entry that matches the current language
                    const localization =
                      videoAttributes.localizations.data.find(
                        (loc) => loc.attributes.locale === currentLanguage
                      );
                    // console.log(localization);
                    if (localization) {
                      // Extract title from the localization data
                      const localizationTitle =
                        localization.attributes.title[0]?.children[0]?.text;
                      // console.log(localizationTitle);
                      // console.log(
                      //   "first",
                      //   localization.attributes.title[0]?.children[0]?.text
                      // );
                      if (localizationTitle) {
                        title = localizationTitle; // Use the title from the localization data
                      }
                    }
                  } else {
                    const localization = videoAttributes;
                    // console.log(localization);
                    if (localization) {
                      // Extract title from the localization data
                      const localizationTitle =
                        localization.title[0]?.children[0]?.text;
                      // console.log(localizationTitle);
                      // console.log(
                      //   "first",
                      //   localization.title[0]?.children[0]?.text
                      // );
                      if (localizationTitle) {
                        title = localizationTitle; // Use the title from the localization data
                      }
                    }
                  }
                  // console.log(currentLanguage, title);
                  return (
                    <div key={index} className="videoinfo" style={{ marginTop: 5 }}>
                      <div className="ratio c_media_youtube">
                        <VideoPlayer
                          key={index}
                          videoId={getVideoIdFromLink(youtubeData.url)}
                        />
                      </div>
                      <div className="content" style={{ marginTop: 5 }}>
                        <div className="heading txt_2">{title}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="auto cm_2">
              <div className="content">
                <hr />
              </div>
            </div>

            <div className="auto cm_16">
              <div className="divide heading txt_1">{t("realEstateGuide")}</div>
              <div className="auto row wrap spaced cols_2">
                {content.map((item, i) => {
                  const slug = generateSlug(item.attributes.title);
                  return (
                    <div className="box" key={i} style={{ marginBottom: 15 }}>
                      <img
                        className=""
                        alt="wohnung-mallorca-kaufen-sae-mallorca.jpg"
                        src={
                          img_url + item.attributes.image.data[0].attributes.url
                        }
                      />
                      <div className="content">
                        <div className="heading txt_2">
                          {item.attributes.title}
                        </div>
                        <div>
                          <p>
                            {item.attributes.small_description}
                            <strong>
                              <Link
                                to={{
                                  pathname:
                                    item.attributes.content_field == null
                                      ? "/ratgeber"
                                      : `/ratgeberdetails/${slug}`,
                                  state: {
                                    img:
                                      img_url +
                                      item.attributes.image.data[0].attributes
                                        .url,
                                    engContent:
                                      item.attributes.locale === "en"
                                        ? item.attributes.content_field
                                        : item.attributes.localizations.data.find(
                                            (loc) =>
                                              loc.attributes.locale === "en"
                                          )?.attributes.content_field,
                                    spaContent:
                                      item.attributes.locale === "es"
                                        ? item.attributes.content_field
                                        : item.attributes.localizations.data.find(
                                            (loc) =>
                                              loc.attributes.locale === "es"
                                          )?.attributes.content_field,
                                    gerContent:
                                      item.attributes.locale === "de"
                                        ? item.attributes.content_field
                                        : item.attributes.localizations.data.find(
                                            (loc) =>
                                              loc.attributes.locale === "de"
                                          )?.attributes.content_field,
                                  },
                                }}
                                title={item.attributes.title}
                                style={{ marginTop: 8 }}
                              >
                                {t("readMore")}
                              </Link>
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Counselor;
