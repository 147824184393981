import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { base_url } from "./../../api/const";
import VideoPlayer from "../../components/VideoPlayer";
import counselor_pic1 from '../../assets/img/counselor_pic1.webp';
import counselor_pic2 from '../../assets/img/counselor_pic2.webp';
import counselor_pic3 from '../../assets/img/counselor_pic3.webp';
import counselor_pic4 from '../../assets/img/counselor_pic4.webp';
import counselor_pic5 from '../../assets/img/counselor_pic5.webp';
const LifestyleGuide = () => {
	const { i18n, t } = useTranslation();
	const [videoData, setVideoData] = useState([]);
	const [loading, setLoading] = useState(true)
	  const getVideoIdFromLink = (link) => {
		const url = new URL(link);
		return url.pathname === '/watch' ? url.searchParams.get('v') : url.pathname.slice(1);
	  };
	  const token =
	  "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
	useEffect(() => {
	  let lang = i18n?.language.startsWith("en")
      ? "en"
      : i18n?.language.startsWith("es")
      ? "es"
      : i18n?.language.startsWith("de")
      ? "de"
      : "de";
	  // console\.log(lang);
	
	  axios
		.get(`${base_url}/videos?locale=${lang}&sort=publishedAt:asc&pagination[pageSize]=50`, {
		  method: "GET",
		  headers: {
			Authorization: `Bearer ${token}`,
		  },
		})
		.then((response) => {
		  // // console.log(response.data.data);
		  const filteredData = response.data.data.filter(item => item.attributes.menu === "Life Style");
		  // // console.log(filteredData, "filtered================================");
		    setVideoData(filteredData);
		  setLoading(false);
		})
		.catch((error) => {
		  // // console.log(error.message);
		  setLoading(false);
		});
	}, [i18n.language]);
	if (loading)
		return (
		  <div
			style={{
			  display: "flex",
			  minHeight: 600,
			  justifyContent: "center",
			  alignItems: "center",
			  flex: 1,
			}}
		  >
			<p>{t("loadingText")}</p>;
		  </div>
		);
  return (
    <>
		<div className="innercontent_wrap">
			<div className="innercontent">
				<div className="e_data_ids">
					<div className="auto cm_2">
						<div className="heading divide">
							{t('lifestyleGuide')}
						</div>
					</div>
					<div className="auto cm_17">
					<div className="auto row spaced cols_2"   style={{ direction: "flex", flexWrap: "wrap" }}>
							
							{videoData.map((video, index) => {
	
								const videoAttributes = video.attributes;
								 const youtubeData = JSON.parse(videoAttributes.youtube);
								 // // console.log("YOUTUBE DATA",youtubeData)
	
								 // Default title to youtubeData.title
								 
								 return (
									<React.Fragment key={index}>
									<div key={index} className="videoinfo" style={{ marginTop: 5 }}>
									  <div className="ratio c_media_youtube">
										<VideoPlayer
										  key={index}
										  videoId={getVideoIdFromLink(youtubeData.url)}
										  url = {youtubeData.url}
										/>
									  </div>
									  <div className="content" style={{ marginTop: 5 }}>
										<div className="heading txt_2">{video.attributes.title[0].children[0].text}</div>
									  </div>
									  {/* {(index + 1) % 2 === 0 && index + 1 !== videoData.length && (
										<div className="auto cm_2" key={`divider-${index}`} style={{width:'100%'}}>
										  <div className="content">
											<hr />
										  </div>
										</div>
									  )}
									  */}
									</div>
									
									</React.Fragment>
								  );
								})}
							{/* <div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" data-src="https://www.youtube-nocookie.com/embed/-5JHo7owG60?rel=0" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/-5JHo7owG60?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										SON VIDA - DAS BEL AIR VON MALLORCA
									</div>
								</div>
							</div> */}
						</div>
					</div>

				
				</div>
			</div>
		</div>
    </>
  );
};

export default LifestyleGuide;
