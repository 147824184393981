import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { base_url } from "./../../api/const";
import VideoPlayer from "../../components/VideoPlayer";
const VideoTouren = () => {
  const { i18n, t } = useTranslation();
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const getVideoIdFromLink = (link) => {
    const url = new URL(link);
    return url.pathname === "/watch"
      ? url.searchParams.get("v")
      : url.pathname.slice(1);
  };
  const token =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
  useEffect(() => {
    let lang = i18n?.language.startsWith("en")
      ? "en"
      : i18n?.language.startsWith("es")
      ? "es"
      : i18n?.language.startsWith("de")
      ? "de"
      : "de";
    // console.log(lang);
    axios
      .get(
        `${base_url}/header-video-tours?locale=${lang}&sort=publishedAt:asc`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response.data.data);
        setVideoData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.message);
        setLoading(false);
      });
  }, [i18n.language]);
  if (loading)
    return (
      <div
        style={{
          display: "flex",
          minHeight: 600,
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <p>{t("loadingText")}</p>;
      </div>
    );
  return (
    <>
      {/* {videoData.map((video) => (
        <VideoPlayer key={video.id} videoId={getVideoIdFromLink(video.link)} /> 
      ))}
		*/}
      <div className="innercontent_wrap">
        <div className="innercontent">
          <div className="e_data_ids">
            <div className="auto cm_17">
              <div className="divide heading txt_1">{t("videoTour")}</div>

              <div
                className="auto row spaced cols_2"
                style={{ direction: "flex", flexWrap: "wrap" }}
              >
                {videoData.map((video, index) => {
                  const videoAttributes = video.attributes;
                  const youtubeData = JSON.parse(videoAttributes.youtube);

                  // Default title to youtubeData.title

                  return (
                    <div
                      key={index}
                      className="videoinfo"
                      style={{ marginTop: 5 }}
                    >
                      <div className="ratio c_media_youtube">
                        <VideoPlayer
                          key={index}
                          videoId={getVideoIdFromLink(youtubeData.url)}
                        />
                      </div>
                      <div className="content" style={{ marginTop: 5 }}>
                        <div className="heading txt_2">
                          {video.attributes.title[0].children[0].text}
                        </div>
                      </div>
                      {/* {(index + 1) % 2 === 0 && index + 1 !== videoData.length && (
									<div className="auto cm_2" key={`divider-${index}`} style={{width:'100%'}}>
									  <div className="content">
										<hr />
									  </div>
									</div>
								  )}
								  */}
                    </div>
                  );
                })}
                {/* <div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/X4IkW-6Hi4c?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										IMMOBILIEN GEBIETSTOUREN MALLORCA by S.A.E Real Estate Mallorca
									</div>
								</div>
							</div> */}

                {/* <div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/KKCD7-O5F3g?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										MEHR, als ein Immobilienmakler auf Mallorca
									</div>
								</div>
							</div> */}
              </div>
            </div>
            {/* <div className="auto cm_2">
						<div className="content">
							<hr/>
						</div>
					</div> */}
            {/* <div className="auto cm_17">
						<div className="auto row spaced cols_2">
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/TtciUsQhqtE?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										35.000.000 € JAMES BOND VILLA AUF MALLORCA?!
									</div>
									<div>
									</div>
								</div>
							</div>
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/lVKgqBtDiQ4?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										3.450.000 € NEUBAUVILLA AM HAFEN PORT ADRIANO
									</div>
								</div>
							</div>
						</div>
					</div> */}
            {/* <div className="auto cm_2">
						<div className="content">
							<hr/>
						</div>
					</div> */}
            {/* <div className="auto cm_17">
						<div className="auto row spaced cols_2">
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/398aiGcgOJY?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										MALLORCA STEUER TALK-AB 2022 AUSWANDERN FÜR UNTERNEHMER UNMÖGLICH?!
									</div>
								</div>
							</div>
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/wDGlyozXz-8?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										6.200.000 € NEUBAUVILLA IN SON VIDA AUF MALLORCA
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="auto cm_2">
						<div className="content">
							<hr/>
						</div>
					</div>
					<div className="auto cm_17">
						<div className="auto row spaced cols_2">
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/pMChoVEd7kE?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										KAUFPREISFÄLLIGKEIT BEIM IMMOBILIENKAUF AUF MALLORCA
									</div>
								</div>
							</div>
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/QagVdSPPJnw?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										MALLORCA GEBIETSTOUR CALA MAYOR
									</div>
									<div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="auto cm_2">
						<div className="content">
							<hr/>
						</div>
					</div>
					<div className="auto cm_17">
						<div className="auto row spaced cols_2">
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/k0VnZPbKxaw?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										5.500.000 € NEUBAUVILLA IN NOVA SANTA PONSA MALLORCA
									</div>
								</div>
							</div>
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/N1S0yskeK7k?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										MALLORCA STEUER TALK-HOMEOFFICE IN DER FERIENIMMOBILIE LEGAL?!
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="auto cm_2">
						<div className="content">
							<hr/>
						</div>
					</div>
					<div className="auto cm_17">
						<div className="auto row spaced cols_2">
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/2OABrMuWG6E?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										Modernes Neubau projekt an der Playa de Palma
									</div>
								</div>
							</div>
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/gbQm_HzrIgc?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										Mallorca Finca mit Hotellizenz für 3.9 Mio. - nur 10 Min. von Palma entfernt
									</div>
									<div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="auto cm_2">
						<div className="content">
							<hr/>
						</div>
					</div>
					<div className="auto cm_17">
						<div className="auto row spaced cols_2">
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/XtgDVjBtjrY?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										Edle Frontline Wohnung im Südwesten Mallorcas
									</div>
									<div>
									</div>
								</div>
							</div>
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/Pb6-O9QOAe4?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										Historische Stadtvilla in Palma de Mallorca
									</div>
									<div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="auto cm_2">
						<div className="content">
							<hr/>
						</div>
					</div>
					<div className="auto cm_17">
						<div className="auto row spaced cols_2">
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/MmOrUzyoRa4?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										Ehemaliges Stadttheater auf Mallorca zum Kauf
									</div>
								</div>
							</div>
							<div className="">
								<div className="ratio c_media_youtube">
									<iframe title="External Video from youtube.com" allow="fullscreen" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/uNtJhlfpa9E?rel=0">
									</iframe>
								</div>
								<div className="content">
									<div className="heading txt_2">
										Mallorca Meerblick Villa in Nova Santa Ponsa
									</div>
								</div>
							</div>
						</div>
					</div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoTouren;
