import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import about_pic1 from "../../assets/img/about_pic1.jpg";
import authService from "../../api/auth";
import { useTranslation } from "react-i18next";

const AboutUsInfo = ({ engData, spaData, gerData, img }) => {
  const { t, i18n } = useTranslation();
  let content = i18n?.language.startsWith("en")
  ? engData
  : i18n?.language.startsWith("es")
  ? spaData
  : i18n?.language.startsWith("de")
  ? gerData
  : gerData;

  return (
    <section className="aboutinfo">
      <div className="cm_6">
        <div className="row">
          <div className="pic">
            <img src={img} alt="" title="" />
          </div>
          <div className="box">
            <div className="txt_3">{t("aboutUs")}</div>
            <div className="body">
              <p>{content}</p>
            </div>
            <a href="/unser-team" className="c_1">
              {t("readMore")} →
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsInfo;
