import React, {useState, useEffect} from "react";
// import { Link } from "react-router-dom";
import recommendations_pic1 from '../../assets/img/recommendations_pic1.webp';
import recommendations_pic2 from '../../assets/img/recommendations_pic2.webp';
import recommendations_pic3 from '../../assets/img/recommendations_pic3.webp';
import recommendations_pic4 from '../../assets/img/recommendations_pic4.webp';

import recommendations_opt2_pic1 from '../../assets/img/recommendations_opt2_pic1.webp';
import recommendations_opt2_pic2 from '../../assets/img/recommendations_opt2_pic2.webp';
import recommendations_opt2_pic3 from '../../assets/img/recommendations_opt2_pic3.webp';
import recommendations_opt2_pic4 from '../../assets/img/recommendations_opt2_pic4.webp';
import recommendations_opt2_pic5 from '../../assets/img/recommendations_opt2_pic5.webp';

import recommendations_opt3_pic1 from '../../assets/img/recommendations_opt3_pic1.webp';
import recommendations_opt3_pic2 from '../../assets/img/recommendations_opt3_pic2.webp';
import recommendations_opt3_pic3 from '../../assets/img/recommendations_opt3_pic3.webp';
import recommendations_opt3_pic4 from '../../assets/img/recommendations_opt3_pic4.webp';
import recommendations_opt3_pic5 from '../../assets/img/recommendations_opt3_pic5.webp';

import recommendations_opt4_pic1 from '../../assets/img/recommendations_opt4_pic1.webp';
import recommendations_opt4_pic2 from '../../assets/img/recommendations_opt4_pic2.webp';
import recommendations_opt4_pic3 from '../../assets/img/recommendations_opt4_pic3.webp';
import recommendations_opt4_pic4 from '../../assets/img/recommendations_opt4_pic4.webp';

import recommendations_opt5_pic1 from '../../assets/img/recommendations_opt5_pic1.webp';
import recommendations_opt5_pic2 from '../../assets/img/recommendations_opt5_pic2.webp';
import recommendations_opt5_pic3 from '../../assets/img/recommendations_opt5_pic3.webp';
import recommendations_opt5_pic4 from '../../assets/img/recommendations_opt5_pic4.webp';


import recommendations_opt6_pic1 from '../../assets/img/recommendations_opt6_pic1.webp';
import recommendations_opt6_pic2 from '../../assets/img/recommendations_opt6_pic2.webp';
import recommendations_opt6_pic3 from '../../assets/img/recommendations_opt6_pic3.webp';
import recommendations_opt6_pic4 from '../../assets/img/recommendations_opt6_pic4.webp';
import { useHistory } from "react-router-dom";

import property_pic1 from '../../assets/img/property_pic1.png';
import property_pic2 from '../../assets/img/property_pic2.png';
import property_pic3 from '../../assets/img/property_pic3.png';
import property_pic4 from '../../assets/img/property_pic4.png';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import Slider from "react-slick";
import { useFavorites } from "../Context/FavoritesContext";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useTranslation } from "react-i18next";
import useStore from "../../Store/useStore";

var slideopt1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

const PopertiesLike = ({engData, spaData, gerData,}) => {
  const selectLanguage = useStore((state) => state.selectLanguage);
  const { t, i18n } = useTranslation();
	// // console.log("ENG LIKE DATA::",engData);
  // // console.log("ESP LIKE DATA::",spaData);
  // // console.log("GER LIKE DATA::",gerData);
	const history = useHistory();
  const [data, setData] = useState(gerData)
  const { favorites, toggleFavorite, espFavorites, gerFavorites } = useFavorites();
	const formatPrice = (price) => {
		const locale = i18n.language.startsWith('en') ?  'en-US' : 'de-DE' ;
    return new Intl.NumberFormat(locale, {
		  style: 'decimal',
		  minimumFractionDigits: 0,
		  maximumFractionDigits: 0
		}).format(price);
	  };
	  
    const handleCardClick = (item, index) => {
      // console.log("selected", item);
      const engProperty = engData.find((data) => data.elements.objektnr_extern === item.elements.objektnr_extern);
      const gerProperty = gerData.find((data) => data.elements.objektnr_extern === item.elements.objektnr_extern);
      const espProperty = spaData.find((data) => data.elements.objektnr_extern === item.elements.objektnr_extern);
      // console.log("ENG",engProperty)
      // console.log("espData",espProperty)
      // console.log("gerData",gerProperty)
      
      const sanitizeString = (str) => {
        return str
                  .replace(/\s+/g, '-') // Replace spaces with hyphens
                  .replace(/[^\w-]+/g, ''); // Remove all non-word characters except hyphens
      };
    
      const sanitizedOrt = sanitizeString(item.elements.ort);
      const sanitizedObjektart = sanitizeString(item.elements.objektart);
      const refId = sanitizeString(item.elements.objektnr_extern)

      const dynamicUrl = `/propertydetails/${sanitizedOrt}/${item.id}-${sanitizedObjektart}/${refId}`;
  
      setTimeout(() => {
        history.push({
          pathname: dynamicUrl,
        });
      }, 1000);
    }
    useEffect(() => {
      selectLanguage === "en" ? setData(engData) : selectLanguage === "es" ? setData(spaData) : setData(gerData)
    }, [selectLanguage, engData, spaData, gerData])

    
	return (
	<section className="aboutinfo">
  <div className="cm_15">
	<div className="auto">
		<div className="heading txt_1">
		{t('youMightAlsoLike')}
    
		</div>
		{/* <div className="body">
			<p>
			Diese Immobilien könnten Ihnen auch gefallen

			</p>
		</div> */}
		<div className="modf_def_cont_block"
		// className="row spaced"
    >
			    {data.map((item, index) => {
            const isFavorite =
            favorites.some((fav) => fav.elements.objektnr_extern ===  item.elements.objektnr_extern) ||
            espFavorites.some((fav) => fav.elements.objektnr_extern ===  item.elements.objektnr_extern) ||
            gerFavorites.some((fav) => fav.elements.objektnr_extern ===  item.elements.objektnr_extern);
           
              return (
                <div className="c_5" style={{ marginLeft: "0.5vw" }}  key={item.elements.objektnr_extern}>
                  <a
                    // href="#"
                    className={`favicon row keep center_v c_selected_marker ${
                      isFavorite ? "favorite" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      const engProperty = engData.find((data) => data.elements.objektnr_extern === item.elements.objektnr_extern);
                      const gerProperty = gerData.find((data) => data.elements.objektnr_extern === item.elements.objektnr_extern);
                      const espProperty = spaData.find((data) => data.elements.objektnr_extern === item.elements.objektnr_extern);
                      toggleFavorite(engProperty, espProperty, gerProperty);
                    }}
                  >
                    <FavoriteIcon style={{ color: isFavorite ? '#e8c25f' : '#fff' }}/>
                  </a>

                  <div className="c_slider">
                    <div className="slides propertySerarchSlide" >
                      <div className="slides_wrap_info">
                        <Slider {...slideopt1}>
                          {item.elements.urls.map((image, i) => (
                            <div className="link" key={i}>
                              {/* <img
                                className=""
                                alt="not set"
                                title=""
                                src={image.url}
								                loading="lazy"
                                // style={{ height: "50%", objectFit: "contain" }}
                              /> */}
							                <LazyLoadImage
                              src={image.url}
                              alt="Property Image"
                            //   className="property-image"
                              // effect="blur"
                            />
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="row keep spaced short">
                      <div className="location">
                        {item.elements.ort === "" ? "nA" : item.elements.ort}
                      </div>
                      <div>
                        Ref:{" "}
                        {item.elements.objektnr_extern === ""
                          ? "nA"
                          : item.elements.objektnr_extern}
                      </div>
                    </div>
                    {/* <a
                      href="#"
                      className="link"
                      title="Link to property"
                    > */}
                      <div className="heading txt_3" style={{cursor:'pointer'}} onClick={() => handleCardClick(item, index)}>
                        {item.elements.objekttitel === ""
                          ? "Not Set"
                          : item.elements.objekttitel}
                      </div>
                    {/* </a> */}
                    <div className="price">€ {formatPrice(item.elements.kaufpreis)}</div>
                    <div className="row grow keep details">
                      <div className="center_v">
                        <img
                          alt="Schlafzimmer"
                          className="sprites"
                          src={property_pic1}
                        />
                        <div>
                          {item.elements.anzahl_zimmer === ""
                            ? "not set"
                            : parseInt(item.elements.anzahl_zimmer)}
                        </div>
                      </div>
                      <div className="center_v">
                        <img
                          alt="Bäder"
                          className="sprites"
                          src={property_pic2}
                        />
                        <div>
                          {item.elements.anzahl_badezimmer === ""
                            ? "not set"
                            : parseInt(item.elements.anzahl_badezimmer)}
                        </div>
                      </div>
                      <div className="center_v">
                        <img
                          alt="Wohnfläche"
                          className="sprites"
                          src={property_pic3}
                        />
                        <div>
                          {item.elements.wohnflaeche === ""
                            ? "not set"
                            : `${parseInt(item.elements.wohnflaeche)}㎡`}
                        </div>
                      </div>
                      <div className="center_v">
                        <img
                          alt="Grundstück"
                          className="sprites"
                          src={property_pic4}
                        />
                        <div>
                          {item.elements.grundstuecksflaeche === ""
                            ? "not set"
                            : `${parseInt(item.elements.grundstuecksflaeche)}㎡`}
                        </div>
                      </div>
                    </div>
                    <div className="row spaced clicks" style={{paddingBottom:15, cursor:'pointer'}} >
                      <div className="c_1_1" onClick={() => handleCardClick(item, index)}>
                      
                      {t('showEstate')}
					            </div>
                      {/* <a href="#" className="c_1_1">
                      </a> */}
                    </div>
                  </div>
                </div>
              );
            })}
		</div>
	</div>
</div>
    </section>


 
  );
};

export default PopertiesLike;
