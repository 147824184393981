import React from 'react';
import { FaTimes } from 'react-icons/fa';
import './PropertyFilter.css';
import { useTranslation } from 'react-i18next';
const FilterChips = ({
  maxPrice,
  minPrice,
  selectedLocations,
  propertyType,
  noOfBed,
  noOfBath,
  propArea,
  refNo,
  addedLocation,
  setMaxPrice,
  setMinPrice,
  setSelectedLocations,
  setPropertyType,
  setNoOfBed,
  data,
  setData,
  allData,
  setAllData,
  handleClearAll,
  handleRemoveFilter
}) => {
// console.log(noOfBath)
  const { t } = useTranslation();
  const propertyTypeMappings = {
    "haus": t("house"),
    "wohnung": t("apartment"),
    "grundstueck": t("land"),
    "gastgewerbe": t("commercial"),
    "einzelhandel": t("retail"),
    "neubau": t("newBuild"),
    "sonstige": t("other")
  };

  return (
    <div className="filter-chips-container">
      {minPrice && (
        <div className="chip">
          {t('priceFrom')}: {minPrice}
          <FaTimes className="close-icon" onClick={() => handleRemoveFilter('minPrice')} />
        </div>
      )}
      {refNo && (
        <div className="chip">
          {t('refNumber')}: {refNo}
          <FaTimes className="close-icon" onClick={() => handleRemoveFilter('refNo')} />
        </div>
      )}
      {maxPrice && (
        <div className="chip">
          {t('priceTo')}: {maxPrice}
          <FaTimes className="close-icon" onClick={() => handleRemoveFilter('maxPrice')} />
        </div>
      )}
      {selectedLocations?.map((location) => (
        <div key={location?.id} className="chip">
          {location.name}
          <FaTimes className="close-icon" onClick={() => handleRemoveFilter('selectedLocations', location)} />
        </div>
      ))}
      {addedLocation.map((location) => (
        <div key={location.id} className="chip">
          {location.name}
          <FaTimes className="close-icon" onClick={() => handleRemoveFilter('addedLocations', location)} />
        </div>
      ))}
      {propertyType.map((type, index) => (
        <div key={index} className="chip">
           {propertyTypeMappings[type]}
          <FaTimes className="close-icon" onClick={() => handleRemoveFilter('propertyType', type)} />
        </div>
      ))}
      {noOfBed && (
        <div className="chip">
          {t('bedrooms')}: {noOfBed}
          <FaTimes className="close-icon" onClick={() => handleRemoveFilter('noOfBed')} />
        </div>
      )}
      {noOfBath && (
        <div className="chip">
          {t('bathrooms')}: {noOfBath}
          <FaTimes className="close-icon" onClick={() => handleRemoveFilter('noOfBath')} />
        </div>
      )}
      {propArea && (
        <div className="chip">
          {t('area')}: {propArea}
          <FaTimes className="close-icon" onClick={() => handleRemoveFilter('propArea')} />
        </div>
      )}
      <button className="clear-filters-button" style={{alignItems:'center'}} onClick={handleClearAll}><FaTimes style={{paddingRight:5, paddingBottom:2, fontSize:18}} />{t('clearFilters')}</button>
    </div>
  );
};

export default FilterChips;
