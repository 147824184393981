// src/store/useStore.js
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";


const defaultFilters = {
  refNo: '',
  minPrice: '',
  maxPrice: '',
  selectedLocations: [],
  propertyType: [],
  noOfBed: '',
  noOfBath: '',
  propArea: '',
  addedLocation: [],
};
const useStore = create(
  persist(
    (set) => ({
      data: [],
      // alldata: [],
      // estates: [],
      loading: true,
      loadingGer: true,
      loadingSpa: true,
      selectLanguage: "",

      // dataESP: [],
      // alldataESP: [],
      // estatesESP : [],

      // dataGER: [],
      // alldataGER: [],
      // estatesGER : [],

      // dataENG: [],
      // alldataENG: [],
      // estatesENG : [],

      // setData: (data) => set({ data }),
 
      // // setEstates: (data) => set({ estates: data }),
      // setLoading: (loading) => set({ loading }),
      // setLoadingGer: (loadingGer) => set({ loadingGer }),
      // setLoadingSpa: (loadingSpa) => set({ loadingSpa }),

      setSelectLanguage: (selectLanguage) => set({ selectLanguage }),

      // setDataESP: (dataESP) => set({ dataESP }),
      // setAllDataESP: (alldataESP) => set({ alldataESP }),
      // setEstatesESP: (estatesESP) => set({ estatesESP }),

      // setDataENG: (dataENG) => set({ dataENG }),
      // setAllDataENG: (alldataENG) => set({ alldataENG }),
      // setEstatesENG: (estatesENG) => set({ estatesENG }),

      // setDataGER: (dataGER) => set({ dataGER }),
      // setAllDataGER: (alldataGER) => set({ alldataGER }),
      // setEstatesGER: (estatesGER) => set({ estatesGER }),
      filters: defaultFilters,

      setFilters: (newFilters) =>
        set((state) => ({
          filters: { ...state.filters, ...newFilters },
        })),

      clearFilters: () => set({ filters: defaultFilters }),
    }),
    {
      name: "property-store", // unique name for the local storage key
      partialize: (state) => ({
        selectLanguage: state.selectLanguage,
      }),
    }
  )
);

// export const useInitializeStoreLanguage = () => {
//   useLanguageInitializer();
// };

export default useStore;
