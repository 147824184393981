import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
const HorizontalIN = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [conSrc, setConSrc] = useState("");
  useEffect(() => {
    currentLanguage.startsWith("en")
      ? setConSrc(
          "https://lionsgatecapital.com/lionsgate-capital-prh-calculator"
        )
      : currentLanguage.startsWith("es")
      ? setConSrc(
          "https://lionsgatecapital.com/es/lionsgate-capital-prh-calculadora"
        )
      : setConSrc(
          "https://lionsgatecapital.com/de/lionsgate-capital-prh-hypotekenr"
        );
  }, [currentLanguage]);

  return (
    <div className="auto cm_2">
      <div className="content">
        <h1>{t("Mortage")}</h1>
       
        <p>{t("LionsgateContent")}</p>
      </div>
      <div style={{ width: "100%", height: "700px", marginTop:"25px" }}>
        <iframe
          frameBorder="0"
          src={conSrc}
          width="100%"
          height="700"
          title="Horizontal IN"
        ></iframe>
      </div>
    </div>
  );
};

export default HorizontalIN;
