import React from "react";
import { Link } from "react-router-dom";
import properties_pic1 from '../../assets/img/lproperties_pic.jpg';
import { useTranslation } from 'react-i18next';


const DiscoverAllproperties = ({ engData, spaData, gerData, img }) => {
	const { t, i18n } = useTranslation();
	let content =
	i18n?.language.startsWith("en")
	? engData
	: i18n?.language.startsWith("es")
	? spaData
	: i18n?.language.startsWith("de")
	? gerData
	: gerData;
	const goToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}
  return (
	<section className="aboutinfo">
	<div className="cm_6">
	<div className="row">
		<div className="pic">
			<img src={img} alt="" title=""/>
		</div>
		<div className="box">
			<div className="txt_3">
				{/* Alle Immobilien entdecken */}
				{t('discoverAllProperties')}
			</div>
			<div className="body">
				<p>
					{/* Entdecken Sie hier alle unsere traumhaften Immobilien auf Mallorca. */}
					{content}
				</p>
			</div>
			<Link to="/propertysearch" className="c_1" onClick={goToTop}>{t('viewNow')} →</Link>
		</div>
	</div>
</div>
    </section>


 
  );
};

export default DiscoverAllproperties;
