import React from "react";
import { Link } from "react-router-dom";
import immobilieVerkaufen_add from '../../assets/img/immobilieVerkaufen_add.jpg';
import { useTranslation } from "react-i18next";

const ImmobilieVerkaufen = ({ engData, spaData, gerData, img }) => {
	const { t, i18n } = useTranslation();
	let content =
	i18n?.language.startsWith("en")
	? engData
	: i18n?.language.startsWith("es")
	? spaData
	: i18n?.language.startsWith("de")
	? gerData
	: gerData;
  return (
	<section className="immobilieVerkaufen">
		<div className="auto cm_2">
			<div className="heading divide">
			{t('saeRealEstate')}
			</div>
			<div className="content">
			<p>{t('exclusivePropertiesDescription')}</p>
			</div>
		</div>

		<div className="cm_6">
			<div className="row">
				<div className="pic">
			<img src={img} alt="" title=""/>
				</div>
				<div className="box">
					<div className="txt_3">
					{t('sellProperty')}
					</div>
					<div className="body">
					<p>{content}</p>
					</div>
					<a href="#" className="c_1">{t('readMore')} →</a>
				</div>
			</div>
		</div>
    </section>


 
  );
};

export default ImmobilieVerkaufen;
