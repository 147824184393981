// ImageModal.js
import React, { useState } from 'react';
import { Modal, Box, IconButton, Typography } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CloseIcon from '@mui/icons-material/Close';

const ImageModal = ({ mainImage, handlePrevClick, handleNextClick }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <LazyLoadImage
        alt="Main image"
        // title="Main image"
        src={mainImage}
        effect="blur"
        style={{ height: 400, width: 700, objectFit: 'cover', cursor:'pointer' }}
        onClick={handleOpen}
      />

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
          }}
        >
          {/* <Box
            sx={{
              position: 'relative',
              width: '70%',
              height: '70%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          > */}
            <LazyLoadImage
              src={mainImage}
              alt="Full-screen view"
              effect="blur"
              style={{
                width: '80vw',
                height: '80vh',
                objectFit: 'contain',
              }}
            />
           
          {/* </Box> */}
        <IconButton
              sx={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  color: 'white',
                  alignItems: 'center',
                  display: 'flex'
                }}
                onClick={handleClose}
                >
                    <Typography sx={{fontSize:18,}}>
                        close
                        </Typography>
                        <Typography sx={{fontSize:22, ml:1 }}>
                            (X)
                            </Typography>
              {/* <CloseIcon /> */}
            </IconButton>

            <button
              className="nav-button prev-button-modal"
              style={{
                position: 'absolute',
                left: 10,
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'white',
                fontSize: '24px',
                paddingLeft:5
              }}
              onClick={handlePrevClick}
            >
              «
            </button>
            <button
              className="nav-button next-button-modal"
              style={{
                position: 'absolute',
                right: 10,
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'white',
                fontSize: '24px',
              }}
              onClick={handleNextClick}
            >
              »
            </button>
                </Box>
      </Modal>
    </>
  );
};

export default ImageModal;
