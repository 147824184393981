import React, { useState, useEffect } from 'react';
import './CookieConsent.css'; // Import the CSS file for styling
import { useTranslation } from 'react-i18next';

const CookieConsent = ({ onAccept }) => {
  const [isVisible, setIsVisible] = useState(false);

  const {t} = useTranslation()

  useEffect(() => {
    const hasUserConsent = localStorage.getItem('cookieConsent');
    if (!hasUserConsent) setIsVisible(true);
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', true);
    setIsVisible(false);
    // onAccept();
  };

  return isVisible ? (
    <div className="cookie-consent">
      <p className="cookie-message">
       {t('cookieText')}
      </p>
      <button className="cookie-button ok" onClick={handleAccept}>
        OK
      </button>
    </div>
  ) : null;
};

export default CookieConsent;