import React, { useState, useEffect } from "react";
import property_pic1 from "../../assets/img/property_pic1.png";
import property_pic2 from "../../assets/img/property_pic2.png";
import property_pic3 from "../../assets/img/property_pic3.png";
import property_pic4 from "../../assets/img/property_pic4.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import { useHistory, useLocation } from "react-router-dom";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useFavorites } from "../../components/Context/FavoritesContext";
import { useTranslation } from "react-i18next";

var slideopt1 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Favorites = () => {
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { favorites, toggleFavorite, gerFavorites, espFavorites } =
    useFavorites();
  const [selectedDATA, setSelectedDATA] = useState([]);

  const property = location?.state?.data || [];
  // console.log("PROPERTY::: ", property);

  const espProperty = location?.state?.espData || [];

  const gerProperty = location?.state?.gerData || [];

  const selectLanguage = i18n.language.startsWith("en")
    ? "en"
    : i18n.language.startsWith("es")
    ? "es"
    : "de";

  useEffect(() => {
    if (selectLanguage === "de") {
      setSelectedDATA(gerFavorites);
    } else if (selectLanguage === "es") {
      setSelectedDATA(espFavorites);
    } else {
      setSelectedDATA(favorites);
    }
  }, [selectLanguage, favorites, gerFavorites, espFavorites]);

  const formatPrice = (price) => {
    const locale = i18n.language.startsWith("en") ? "en-US" : "de-DE";
    return new Intl.NumberFormat(locale, {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const handleCardClick = (item) => {
    // console.log("first");
    const engProp = favorites.find(
      (data) => data.elements.objektnr_extern === item.elements.objektnr_extern
    );
    const gerProp = gerFavorites.find(
      (data) => data.elements.objektnr_extern === item.elements.objektnr_extern
    );
    const espProp = espFavorites.find(
      (data) => data.elements.objektnr_extern === item.elements.objektnr_extern
    );
    const sanitizeString = (str) => {
      return str

        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .replace(/[^\w-]+/g, ""); // Remove all non-word characters except hyphens
    };

    const sanitizedOrt = sanitizeString(item.elements.ort);
    const sanitizedObjektart = sanitizeString(item.elements.objektart);
    const refId = sanitizeString(item.elements.objektnr_extern);

    const dynamicUrl = `/propertydetails/${sanitizedOrt}/${item.id}-${sanitizedObjektart}/${refId}`;

    setTimeout(() => {
      history.push({
        pathname: dynamicUrl,
        state: {
          property: engProp,
          gerProperty: gerProp,
          espProperty: espProp,
        },
      });
    });
  };

  const handleToggleFavorite = (item) => {
    // toggleFavorite(item);

    const engProp = favorites.find(
      (data) => data.elements.objektnr_extern === item.elements.objektnr_extern
    );
    const gerProp = gerFavorites.find(
      (data) => data.elements.objektnr_extern === item.elements.objektnr_extern
    );
    const espProp = espFavorites.find(
      (data) => data.elements.objektnr_extern === item.elements.objektnr_extern
    );
    // console.log(espProp, "???????????");
    // console.log(gerProp);
    // console.log(engProp);

    toggleFavorite(engProp, espProp, gerProp);
    setSelectedDATA((prevSelectedDATA) =>
      prevSelectedDATA.filter((fav) => fav.id !== item.id)
    );
  };

  const favoriteProperties = property.filter((item) =>
    favorites.some((fav) => fav.id === item.id)
  );
  const favoritePropertiesEsp = gerProperty.filter((item) =>
    favorites.some((fav) => fav.id === item.id)
  );
  const favoritePropertiesGer = espProperty.filter((item) =>
    favorites.some((fav) => fav.id === item.id)
  );

  const isFavorite = (item) =>
    favorites.some(
      (fav) => fav.elements.objektnr_extern === item.elements.objektnr_extern
    ) ||
    espFavorites.some(
      (fav) => fav.elements.objektnr_extern === item.elements.objektnr_extern
    ) ||
    gerFavorites.some(
      (fav) => fav.elements.objektnr_extern === item.elements.objektnr_extern
    );
  return (
    <>
      <section className="aboutinfo">
        {/* //background-color: #f0f0f1; */}
        <div style={{ backgroundColor: "#f0f0f1", paddingTop: 10 }}>
          <header
            style={{ fontWeight: 400, fontSize: "36px", marginLeft: "3vw" }}
          >
            {t("yourFavorites")}
          </header>
        </div>
        <div className="cm_15">
          <div className="auto">
            {selectedDATA.length > 0 ? (
              <div
                className="modf_def_cont_block"
                // style={{
                //   flexWrap: "wrap",
                //   display: "flex",
                //   flexDirection: "row",
                // }}
              >
                {selectedDATA.map((item) => {
                  // const isFavorite = favoriteProperties.some(
                  //   (fav) => fav.id === item.id
                  // );
                  return (
                    <div
                      className="c_5"
                      style={{ marginLeft: "0.5vw" }}
                      key={item.elements.objektnr_extern}
                    >
                      <a
                        href=""
                        className={`favicon row keep center_v c_selected_marker ${
                          isFavorite(item) ? "favorite" : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleToggleFavorite(item);
                        }}
                      >
                        <FavoriteIcon
                          style={{ color: isFavorite(item) ? "#e8c25f" : "" }}
                        />
                      </a>

                      <div className="c_slider">
                        <div className="slides propertySerarchSlide">
                          <div className="slides_wrap_info">
                            <Slider {...slideopt1}>
                              {item.elements.urls.map((image, i) => (
                                <div className="link" style={{cursor: "pointer"}} key={i}>
                                  <img
                                  src={image.url}
                                  alt="Property Image"
                                  onClick={()=>handleCardClick(item)}
                                  // effect="blur"
                                  // loading="lazy"
                                  // srcSet={`${image.url}?w=100 100w, ${image.url}?w=300 300w, ${image.url}?w=600 600w`}
                                  style={{ height: 220, width: 450,cursor: "pointer" }}
                                />
                                </div>
                              ))}
                            </Slider>
                          </div>
                        </div>
                      </div>
                      <div className="box">
                        <div className="row keep spaced short">
                          <div className="location">
                            {item.elements.ort === ""
                              ? "nA"
                              : item.elements.ort}
                          </div>
                          <div>
                            Ref:{" "}
                            {item.elements.objektnr_extern === ""
                              ? "nA"
                              : item.elements.objektnr_extern}
                          </div>
                        </div>
                        <div
                          className="heading txt_3"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleCardClick(item)}
                        >
                          {item.elements.objekttitel === ""
                            ? "Not Set"
                            : item.elements.objekttitel}
                        </div>
                        <div className="price">
                          € {formatPrice(item.elements.kaufpreis)}
                        </div>
                        <div className="row grow keep details">
                          <div className="center_v">
                            <img
                              alt="Schlafzimmer"
                              className="sprites"
                              src={property_pic1}
                            />
                            <div>
                              {item.elements.anzahl_zimmer === ""
                                ? "not set"
                                : parseInt(item.elements.anzahl_zimmer)}
                            </div>
                          </div>
                          <div className="center_v">
                            <img
                              alt="Bäder"
                              className="sprites"
                              src={property_pic2}
                            />
                            <div>
                              {item.elements.anzahl_badezimmer === ""
                                ? "not set"
                                : parseInt(item.elements.anzahl_badezimmer)}
                            </div>
                          </div>
                          <div className="center_v">
                            <img
                              alt="Wohnfläche"
                              className="sprites"
                              src={property_pic3}
                            />
                            <div>
                              {item.elements.wohnflaeche === ""
                                ? "not set"
                                : `${parseInt(item.elements.wohnflaeche)}㎡`}
                            </div>
                          </div>
                          <div className="center_v">
                            <img
                              alt="Grundstück"
                              className="sprites"
                              src={property_pic4}
                            />
                            <div>
                              {item.elements.nutzflaeche === ""
                                ? "not set"
                                : `${parseInt(item.elements.nutzflaeche)}㎡`}
                            </div>
                          </div>
                        </div>
                        <div
                          className="row spaced clicks"
                          onClick={() => handleCardClick(item)}
                        >
                          <div></div>
                          <a href="#" className="c_1_1">
                            {t("showEstate")}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FavoriteBorderIcon
                    style={{ fontSize: "4rem", color: "#e8c25f" }}
                  />
                  <span style={{ marginTop: 10 }}>
                    {/* No properties saved in favorites. */}
                    {t("noFavorites")}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Favorites;
