import React from 'react';

const HorizontalDE = () => {
  return (
    <div style={{ width: '100%', height: '700px' }}>
      <iframe
        frameBorder="0"
        src="https://lionsgatecapital.com/de/lionsgate-capital-prh-hypotekenr"
        width="100%"
        height="700"
        title="Horizontal IN"
      ></iframe>
    </div>
  );
};

export default HorizontalDE;