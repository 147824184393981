import React from "react";
import { Link } from "react-router-dom";
import about_pic1 from '../../assets/img/about_pic1.jpg';
import Map from "./Map";
import { useTranslation } from "react-i18next";
const FurtherInformation = ({lat, long}) => {
	const { t } = useTranslation();
	// console.log('Lat', lat, 'Long', long);
  return (
	<>
	<div className="c_9 further_Information_block">
		<div className="auto">
			<div className="heading txt_2">
			{t('additionalInformation')}
			</div>
			<div className="row spaced">
				<div className="box">
					<div className="label">
					{t('map')}
					</div>
					<div className="c_map_mapbox mapboxgl-map">
						<div className="mapboxgl-canvas-container mapboxgl-interactive mapboxgl-touch-drag-pan mapboxgl-touch-zoom-rotate">
							<div className="googlemap2">
							{ lat === '' || long === '' ? <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3076.761559194502!2d2.738673274542985!3d39.542432671593495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sC%2FCan%20Calafat%2056%2C%20Planta%201%2C%20Puerta%2011%2007199%20Palma%20de%20Mallorca!5e0!3m2!1sen!2sin!4v1714388233437!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> :
							<Map latitude={lat} longitude={long} />
							}
							</div>
						</div>
						
					</div>
				</div>
				<div className="box">
					<div className="label">
					{t('disclaimer')}
					</div>
					<p className="body">
					{t('disclaimerText')}
					</p>
				</div>
				<div className="box">
					<div className="label">
					{t('energyCertificate')}
					</div>
					<p className="sublabel">
					{t('energyCertificateAppliedFor')}
					</p>
					<div className="c_12 inactive">
						<div className="row spaced">
							<div className="indicator i_0 bgopt1" >
								A
							</div>
							<div className="hide">
								A
							</div>
						</div>
						<div className="row spaced">
							<div className="indicator i_1 bgopt2" >
								B
							</div>
							<div className="hide">
								B
							</div>
						</div>
						<div className="row spaced">
							<div className="indicator i_2 bgopt3" >
								C
							</div>
							<div className="hide">
								C
							</div>
						</div>
						<div className="row spaced">
							<div className="indicator i_3 bgopt4" >
								D
							</div>
							<div className="hide">
								D
							</div>
						</div>
						<div className="row spaced">
							<div className="indicator i_4 bgopt5">
								E
							</div>
							<div className="hide">
								E
							</div>
						</div>
						<div className="row spaced">
							<div className="indicator i_5 bgopt6" >
								F
							</div>
							<div className="hide">
								F
							</div>
						</div>
						<div className="row spaced">
							<div className="indicator i_6 bgopt7" >
								G
							</div>
							<div className="hide">
								G
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</>


 
  );
};

export default FurtherInformation;
