import React from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function RedirectToSearch() {
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    setTimeout(() => {
      history.replace({
        pathname: "/propertysearch",
        state: location.state,
      });
    }, 100);
  }, []);
  return <></>;
}
