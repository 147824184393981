import React, { useEffect, useState } from "react";
import authService from "../../api/auth";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { base_url } from "./../../api/const";
import ReactMarkdown from "react-markdown";
const Impressum = () => {
  const { i18n, t } = useTranslation();
  const [addressheader, setAddressheader] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [addressLine4, setAddressLine4] = useState("");
  const [representBy, setRepresentBy] = useState([]);
  const [telephone, setTelephone] = useState("");
  const [mail, setMail] = useState("");
  const [vatId, setVatId] = useState("");
  const [disclaimer, setDisclaimer] = useState("");

  const [loading, setLoading] = useState(true);
  const token =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
  useEffect(() => {
    let lang = i18n?.language.startsWith("en")
    ? "en"
    : i18n?.language.startsWith("es")
    ? "es"
    : i18n?.language.startsWith("de")
    ? "de"
    : "de";
    // console.log(lang);
    axios
      .get(`${base_url}/imprint?locale=${lang}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data.data.attributes);
        setAddressheader(response.data.data.attributes.address_header);
        setAddressLine1(response.data.data.attributes.adressline1);
        setAddressLine2(response.data.data.attributes.addressline2);
        setAddressLine3(response.data.data.attributes.addressline3);
        setAddressLine4(response.data.data.attributes.addressline4);
        setRepresentBy(response.data.data.attributes.represented_by);
        setTelephone(response.data.data.attributes.contact_telephone);
        setMail(response.data.data.attributes.contact_email);
        setVatId(response.data.data.attributes.vat_id);
        setDisclaimer(response.data.data.attributes.disclaimer);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.message);
        setLoading(false);
      });
  }, [i18n.language]);
  if (loading)
    return (
      <div
        style={{
          display: "flex",
          minHeight: 600,
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <p>{t("loadingText")}</p>;
      </div>
    );
  return (
    <>
      <div className="e_data_ids cmscontent">
        <div className="auto cm_2">
          <div className="heading divide" style={{fontSize:"36px"}}>{t("imprint")}</div>

          <div className="content" style={{ marginTop: "15px" }}>
            {/* <p> */}
            <strong>{addressheader}</strong>

            <p style={{ marginBottom: "3px", paddingTop: "3px" }}>
              {addressLine1}
            </p>
            <p style={{ marginBottom: "0px", paddingTop: "3px" }}>
              {addressLine2}
            </p>
            {/* {addressLine3}
          {addressLine4} */}
            <p style={{ marginBottom: "0px", paddingTop: "3px" }}>
              {addressLine3}
            </p>
            <p style={{ marginBottom: "20px", paddingTop: "3px" }}>
              {addressLine4}
            </p>
            {/* </p> */}
            <p>
              <strong>{t("representBy")}:</strong>
              {representBy.map((item, index) => (
                <p
                  key={index}
                  style={{ marginBottom: "0px", paddingTop: "3px" }}
                >
                  {item.children[0].text}
                </p>
              ))}

              {/* Maurice Klingenschmitt */}
            </p>
            <p>
              <strong style={{ textTransform: "uppercase" }}>
                {t("contact")}:
              </strong>
              {t("telephoneText")}: {telephone}
              <p style={{ marginBottom: "0px", paddingTop: "3px" }}>
                {t("emailText")}: {mail}
              </p>
            </p>
            <p>
              <strong>{t("taxid")}:</strong>
              {t("taxid2")}: {vatId}
            </p>

            <strong>{t("disclaimerCMS")}</strong>
            <p style={{ marginTop: 5 }}>
              <ReactMarkdown>{disclaimer}</ReactMarkdown>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Impressum;
