import React from 'react';

const HorizontalES = () => {
  return (
    <div style={{ width: '100%', height: '700px' }}>
      <iframe
        frameBorder="0"
        src="https://lionsgatecapital.com/es/lionsgate-capital-prh-calculadora"
        width="100%"
        height="700"
        title="Horizontal IN"
      ></iframe>
    </div>
  );
};

export default HorizontalES;