import React, { createContext, useContext, useState } from 'react';

const FavoritesContext = createContext();

export const useFavorites = () => useContext(FavoritesContext);

export const FavoritesProvider = ({ children }) => {
  const [favorites, setFavorites] = useState([]);
  const [gerFavorites, setGerFavorites] = useState([]);
  const [espFavorites, setEspFavorites] = useState([]);

  const toggleFavorite = (property, espProperty, gerProperty) => {
    const isFavorite = (list, item) => list.some((fav) => fav.elements.objektnr_extern === item.elements.objektnr_extern);
    setFavorites((prevFavorites) => {
      const isAlreadyFavorite = prevFavorites.some((fav) => fav.id === property.id);
      if (isAlreadyFavorite) {
        return prevFavorites.filter((fav) => fav.id !== property.id);
      } else {
        return [...prevFavorites, property];
      }
    });
    setGerFavorites((prevFavorites) => {
      const isAlreadyFavorite = prevFavorites.some((fav) => fav.id === gerProperty.id);
      if (isAlreadyFavorite) {
        return prevFavorites.filter((fav) => fav.id !== gerProperty.id);
      } else {
        return [...prevFavorites, gerProperty];
      }
    });
    setEspFavorites((prevFavorites) => {
      const isAlreadyFavorite = prevFavorites.some((fav) => fav.id === espProperty.id);
      if (isAlreadyFavorite) {
        return prevFavorites.filter((fav) => fav.id !== espProperty.id);
      } else {
        return [...prevFavorites, espProperty];
      }
    });
  };

  return (
    <FavoritesContext.Provider value={{ favorites, toggleFavorite, gerFavorites, espFavorites }}>
      {children}
    </FavoritesContext.Provider>
  );
};
