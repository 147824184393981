import React, { useState, useEffect, useRef, useCallback } from "react";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import pako from "pako";
import { Link } from "react-router-dom";
import phoneicon1 from "../../assets/img/phoneicon1.png";
import phoneicon2 from "../../assets/img/phoneicon2.png";
import property_add from "../../assets/img/property_add1.png";
import property_pic1 from "../../assets/img/property_pic1.png";
import property_pic2 from "../../assets/img/property_pic2.png";
import property_pic3 from "../../assets/img/property_pic3.png";
import property_pic4 from "../../assets/img/property_pic4.png";
import luxusimmobilienadd1 from "../../assets/img/lproperties_pic.jpg";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import PopertiesLike from "../PopertiesLike/PopertiesLike";
import FurtherInformation from "../FurtherInformation/FurtherInformation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PDFDocument } from "pdf-lib";
import { useFavorites } from "../Context/FavoritesContext";
import CheckIcon from "@mui/icons-material/Check";
import { MenuItem } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import toast from "react-hot-toast";
import ImageGallery from "react-image-gallery";
import { EffectFade, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { useTranslation } from "react-i18next";
import "react-image-gallery/styles/css/image-gallery.css";
import useStore from "../../Store/useStore";
import { property_base_url } from "../../api/const";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImageModal from "./ImageModal";
// import CheckIcon from '@mui/icons-material/Check';
const PopertyDescription = () => {
  const [espProp, setEspProp] = useState([]);
  const [engProp, setEngProp] = useState([]);
  const [gerProp, setGgerProp] = useState([]);
  const [esLoading, setEsLoading] = useState(false);
  const [deLoading, setDeLoading] = useState(false);
  const [enLoading, setEnLoading] = useState(false);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);

  const mainToken =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";

  const fetchAllPropertyListEstateData = useCallback(async () => {
    setEnLoading(true);
    axios
      .get(`${property_base_url}properties/en.json`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${mainToken}`,
        },
      })
      .then((response) => {
        // // console.log(response.data)
        // // console.log("Api Eng Data", response.data);
        setEngProp(response.data);
        setEnLoading(false);
      })
      .catch((error) => {
        // console.log(error.message);
        setEnLoading(false);
      });
  }, []);

  const fetchAllPropertyListEstateDataESP = useCallback(async () => {
    setEsLoading(true);
    axios
      .get(`${property_base_url}properties/es.json`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${mainToken}`,
        },
      })
      .then((response) => {
        // // console.log(response.data)
        // // console.log("Api Ger Data", response.data);
        setEspProp(response.data);
        setEsLoading(false);
      })
      .catch((error) => {
        // console.log(error.message);
        setEsLoading(false);
      });
  }, []);
  async function getCityCenter(cityName) {
    const url = `https://nominatim.openstreetmap.org/search?city=${encodeURIComponent(
      cityName
    )}&format=json&limit=1`;

    const response = await fetch(url);
    const payload = await response.json();

    if (payload.length <= 0) return [];

    const cityCenter = [parseFloat(payload[0].lat), parseFloat(payload[0].lon)];

    // // console.log('city lat long', cityCenter)
    setLat(cityCenter[0]);
    setLong(cityCenter[1]);
  }

  const fetchAllPropertyListEstateDataGER = useCallback(async () => {
    setDeLoading(true);
    axios
      .get(`${property_base_url}properties/de.json`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${mainToken}`,
        },
      })
      .then((response) => {
        // // console.log(response.data)
        // // console.log("DAta with Image", response.data);

        setGgerProp(response.data);
        setDeLoading(false);
      })
      .catch((error) => {
        // console.log(error.message);
        setDeLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchAllPropertyListEstateData();
    fetchAllPropertyListEstateDataESP();
    fetchAllPropertyListEstateDataGER();
  }, []);
  const { t, i18n } = useTranslation();
  const selectLanguage = useStore((state) => state.selectLanguage);
  const setSelectLanguage = useStore((state) => state.setSelectLanguage);
  // Alert message
  const Alert = React.forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const { favorites, toggleFavorite, espFavorites, gerFavorites } =
    useFavorites();
  const history = useHistory();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loc, setLoc] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");

  const [likeProperties, setLikeProperties] = useState([]);
  const [likePropertiesGER, setLikePropertiesGER] = useState([]);
  const [likePropertiesESP, setLikePropertiesESP] = useState([]);

  const [property, setProperty] = useState(null);
  const [allImage, setAllImage] = useState([]);

  const [objekttitel, setobjekttitel] = useState("");
  const [ort, setort] = useState("");
  const [objektnr_extern, setobjektnr_extern] = useState("");
  const [anzahl_zimmer, setanzahl_zimmer] = useState("");
  const [anzahl_badezimmer, setanzahl_badezimmer] = useState("");
  const [wohnflaeche, setwohnflaeche] = useState("");
  const [nutzflaeche, setnutzflaeche] = useState("");
  const [kaufpreis, setkaufpreis] = useState("");
  const [objektbeschreibung, setobjektbeschreibung] = useState("");
  const [lage, setlage] = useState("");
  const [ausstatt_beschr, setausstatt_beschr] = useState("");
  const [sonstige_angaben, setsonstige_angaben] = useState("");
  const [balkon, setbalkon] = useState("");
  const [terrasse, setterrasse] = useState("");
  const [warmwasserEnthalten, setwarmwasserEnthalten] = useState("");
  const [toiletten, settoiletten] = useState("");
  const [heizungsart, setheizungsart] = useState([]);
  const [befeuerung, setbefeuerung] = useState([]);
  const [breitengrad, setbreitengrad] = useState("");
  const [laengengrad, setlaengengrad] = useState("");
  const [objektart, setobjektart] = useState("");
  const [showIOS, setShowIOS] = useState(false);

  const [loading, setLoading] = useState(true);
  const [selectedDATA, setSelectedDATA] = useState([]);
  const [salutation, setSalutation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [propLoading, setPropLoading] = useState(false);
  const [error, setError] = useState({ field: "", message: "" });

  const [sold, setSold] = useState("");
  const [reserved, setReserved] = useState("");
  const [publish, setPublish] = useState("");

  const dataENG = useStore((state) => state.dataENG);
  // const setDataENG = useStore((state) => state.setDataENG);
  // const estatesENG = useStore((state) => state.estatesENG);
  // const allDataENG = useStore((state) => state.allDataENG);
  // const setAllDataENG = useStore((state) => state.setAllDataENG);

  const dataESP = useStore((state) => state.dataESP);
  // const setDataESP = useStore((state) => state.setDataESP);
  // const estatesESP = useStore((state) => state.estatesESP);
  // const allDataESP = useStore((state) => state.allDataESP);
  // const setAllDataESP = useStore((state) => state.setAllDataESP);

  const dataGER = useStore((state) => state.dataGER);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!salutation) {
      setError({ field: "salutation", message: t("salutationRequired") });
    } else if (!firstName) {
      setError({ field: "firstName", message: t("firstNameRequired") });
    } else if (!lastName) {
      setError({ field: "lastName", message: t("lastNameRequired") });
    } else if (!email.trim()) {
      setError({ field: "email", message: t("emailRequired2") });
    } else if (!validateEmail(email.trim())) {
      setError({
        field: "email",
        message: t("invalidEmail2"),
      });
    } else if (!phone) {
      setError({ field: "phone", message: t("phoneRequired2") });
    } else if (!message) {
      setError({ field: "message", message: t("messageRequired") });
    } else if (!agreed) {
      setError({
        field: "agreed",
        message: t("privacyPolicyAgreement2"),
      });
    } else {
      // // console.log('Salutation:', salutation);
      // // console.log('First Name:', firstName);
      // // console.log('Last Name:', lastName);
      // // console.log('Email:', email);
      // // console.log('Phone:', phone);
      // // console.log('Message:', message);
      // // console.log('Agreed to terms:', agreed);
      setError({ field: "", message: "" });
      const urlsArray = allImage.map((item) => item.url);
      // property_images: property.elements.urls,
      // // console.log(
      //   urlsArray,
      //   salutation,
      //   firstName,
      //   lastName,
      //   email,
      //   phone,
      //   message
      // );
      submitBookings(
        salutation,
        firstName,
        lastName,
        email,
        phone,
        message,
        urlsArray
      );
    }
  };

  // const [addressId, setAddressId] = useState(null);
  const salutations = [
    { value: "mr2", label: t("mr2") },
    { value: "ms", label: t("ms") },
  ];

  const handleCloses = (event, reason) => {
    setError({ field: "", message: "" });
  };
  const handleClose = () => setShow(false);
  const handleShow = () => {
    // if(/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)){
    //   // window.open(pdfUrl, "_blank")
    //   if(!loading){
    //     setShow(false)
    //    await window.open(pdfUrl, "_blank")
    //   }
    //   else{
    //     setShow(true)
    //   };

    // } else{
    setShow(true);
  };
  // }
  const CryptoJS = require("crypto-js");

  // const property = location?.state?.property || {};
  // console.log(location.pathname)
  // Get the last part of the URL
  const urlSegments = location.pathname.split("/");
  const lastSegment = urlSegments[urlSegments.length - 1];
  const idSegment = urlSegments[urlSegments.length - 2];

  // Split the segment to get the ID (4317) before the hyphen
  const propertyId = idSegment.split("-")[0];
  // Replace hyphens with spaces
  const formattedValue = lastSegment.replace(/-/g, " ");

  const getPropertyDetails = useCallback(async () => {
    setPropLoading(true);
    const token =
      "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
    const language = i18n?.language.startsWith("en")
      ? "en"
      : i18n?.language.startsWith("es")
      ? "es"
      : i18n?.language.startsWith("de")
      ? "de"
      : "de";
    // console.log("<><><><<>>", formattedValue);
    try {
      const response = await axios(
        `${property_base_url}properties/${formattedValue}/${language}.json`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("GET DETAILS ===========", response.data);
      getCityCenter(response.data.ort);
      fetchData(response.data.Id);
      setProperty(response.data);
      setAllImage(response.data.urls);
      setLoc("Plaza de la Porta de Santa Catalina 20,");
      setCity("Palma de Mallorca");
      setZip("07012");
      // fetchLikeProperties();
      setMainImage(response.data.urls[0].url);

      setobjekttitel(response.data.objekttitel);
      setort(response.data.ort);
      setobjektnr_extern(response.data.objektnr_extern);
      setanzahl_zimmer(response.data.anzahl_schlafzimmer);
      setanzahl_badezimmer(response.data.anzahl_badezimmer);
      setwohnflaeche(response.data.wohnflaeche);
      setnutzflaeche(response.data.nutzflaeche);
      setkaufpreis(response.data.kaufpreis);
      setobjektbeschreibung(response.data.objektbeschreibung);
      setlage(response.data.lage);
      setausstatt_beschr(response.data.ausstatt_beschr);
      setsonstige_angaben(response.data.sonstige_angaben);
      setbalkon(response.data.balkon);
      setterrasse(response.data.terrasse);
      setwarmwasserEnthalten(response.data.warmwasserEnthalten);
      settoiletten(response.data.toiletten);
      setheizungsart(response.data.heizungsart);
      setbefeuerung(response.data.befeuerung);
      setbreitengrad(response.data.breitengrad);
      setlaengengrad(response.data.laengengrad);
      setobjektart(response.data.objektart);
      setSold(response.data.verkauft);
      setPublish(response.data.veroeffentlichen);
      setReserved(response.data.reserviert);
      setPropLoading(false);
    } catch (error) {
      console.error("Error fetching video data", error.data);
      setSold("1");
      setReserved("1");
      setPublish("0");
      setPropLoading(false);
    }
  }, [formattedValue, i18n?.language, property_base_url]);

  useEffect(() => {
    getPropertyDetails();
  }, [i18n?.language, location]);

  useEffect(() => {
    fetchLikeProperties();
    fetchLikePropertiesESP();
    fetchLikePropertiesGER();
  }, [location]);
  // // console.log(property);
  // // console.log("GER PROPERTY::: ", location?.state.gerProperty);
  // // console.log("PROPERTY::: ", location?.state.espProperty);
  // const [mainImage, setMainImage] = useState([]);

  // useEffect(() => {
  //   setMainImage(
  //     property?.elements?.urls?.length == 0 ||
  //       property?.elements?.urls == undefined
  //       ? []
  //       : property?.elements?.urls[0]?.url
  //   );
  // }, [location?.state?.property]);

  // useEffect(() => {
  //   // console.log(selectLanguage);
  //   if (selectLanguage === "de") {
  //     setSelectedDATA(location?.state?.gerProperty);
  //   } else if (selectLanguage === "es") {
  //     setSelectedDATA(location?.state?.espProperty);
  //   } else {
  //     setSelectedDATA(location?.state?.property);
  //   }
  //   // console.log("espProp",location?.state?.espProperty);
  //   // console.log("gerProp",location?.state?.gerProperty);
  // }, [selectedDATA, selectLanguage, location?.state?.property]);

  // useEffect(() => {
  //   // console.log(location.state)
  //   if (location.state && location.state.property){
  //     setProperty(location.state.property)
  //     if(location.state?.property?.elements?.urls.length == 0 || location.state.property?.elements?.urls == undefined)
  //     {
  //       setMainImage([])
  //     }else{
  //       setMainImage(location.state.property?.elements?.urls[0]?.url)
  //       fetchData(location.state?.property?.id);
  //       fetchLikeProperties();
  //       window.scrollTo({ top: 0, behavior: "smooth" });
  //     }
  //   }
  // }, [location.state.property])

  const token = "01f760ab735718c491feab1b732d06f3";
  const secret =
    "47750a5bd3115a415fc2988dbc36c421c600efca69eba5cfebc660bc73178677";
  const timestamp = Math.floor(Date.now() / 1000);
  // const type= 'estate'
  function createHmac(token, secret, timestamp, type, actionId) {
    const fields = {
      timestamp: timestamp,
      token: token,
      resourcetype: type,
      actionid: actionId,
    };

    const hmac = CryptoJS.HmacSHA256(
      Object.values(fields).join(""),
      secret
    ).toString(CryptoJS.enc.Base64);

    return hmac;
  }

  const resourceType = "idsfromrelation";
  //   const actionId = "urn:onoffice-dens:smart:2.5:relationTypes:estate:address:buyer";
  const actionId = "urn:onoffice-de-ns:smart:2.5:smartml:action:get";

  const hmac = createHmac(token, secret, timestamp, resourceType, actionId);

  // // console.log("timestamp:", timestamp);
  // // console.log("hmac:", hmac);

  const resourceTypeEstate = "estate";
  const actionIdEstate = "urn:onoffice-de-ns:smart:2.5:smartml:action:read";

  const hmacEstate = createHmac(
    token,
    secret,
    timestamp,
    resourceTypeEstate,
    actionIdEstate
  );

  // // console.log("timestamp:Estate::", timestamp);
  // // console.log("hmac:Estate::", hmac);
  const fetchData = useCallback(
    async (id) => {
      // setLoading(true);
      // let Id = id === undefined ? location.state.property.id : id;

      // // console.log("id:================", id, timestamp, hmac);
      const API_URL = "https://www.sae-mallorca.com/propertywebsite/api/proxy";
      const requestData = {
        token: "01f760ab735718c491feab1b732d06f3",
        request: {
          actions: [
            {
              actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:get",
              resourceid: "",
              timestamp: timestamp,
              hmac_version: "2",
              hmac: hmac,
              // identifier: "urn:onoffice-dens:smart:2.5:relationTypes:estate:address:buyer",
              resourcetype: "idsfromrelation",
              parameters: {
                relationtype:
                  "urn:onoffice-de-ns:smart:2.5:relationTypes:estate:address:contactPersonAll",
                parentids: [id],
                // childids: [4879]
              },
            },
          ],
        },
      };

      try {
        const response = await axios.post(API_URL, requestData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        // // console.log(response.data.data)
        let elements =
          response.data.data?.response?.results[0]?.data?.records[0]?.elements;
        // // console.log(
        //   "Address Id 11",
        //   response.data.data?.response?.results[0]?.data?.records[0]?.elements
        // );
        if (elements) {
          for (let key in elements) {
            if (elements.hasOwnProperty(key)) {
              let valueArray = elements[key];
              if (Array.isArray(valueArray)) {
                // // console.log("INITIAL RESPONSE Address", valueArray[0]);
                getPdf(id, valueArray[0]);
                // fetchAddressData(valueArray[0]);
              }
            }
          }
        }
        //   setEstates(response.data?.response?.results[0]?.data.records);
        //   let IDS = response.data?.response?.results[0]?.data?.records.map((item) =>
        //     parseInt(item.elements.Id)
        //   );
        // // console.log("ID ====", IDS);
        //   fetchAllPropertyListEstateImages(
        //     IDS,
        //     response.data?.response?.results[0]?.data.records
        //   );
      } catch (error) {
        console.error("Error fetching data:", error);
        //   setLoading(false);
      }
    },
    [token, hmac, timestamp]
  );

  // const fetchAddressData = async (id) => {
  //   // console.log("Fetching address data IDDDDDD", id);
  //   const API_URL =
  //     "https://www.sae-mallorca.com/propertywebsite/api/proxy";
  //   const requestData = {
  //     token: "01f760ab735718c491feab1b732d06f3",
  //     request: {
  //       actions: [
  //         {
  //           actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
  //           resourceid: "",
  //           timestamp: timestamp,
  //           hmac_version: "2",
  //           hmac: hmacAddress,
  //           identifier: "",
  //           resourcetype: "address",
  //           parameters: {
  //             recordids: [id],
  //             data: [
  //               "Briefanrede",
  //               "Vorname",
  //               "Name",
  //               "Land",
  //               "Ort",
  //               "Plz",
  //               "Strasse",
  //               "Titel",
  //               "Name",
  //               "Telefon1",
  //               "Telefon2",
  //             ],
  //           },
  //         },
  //       ],
  //     },
  //   };
  //   try {
  //     const response = await axios.post(API_URL, requestData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //     });
  //     // console.log(
  //       "ADDRESS DATA",
  //       response.data.data?.response?.results[0]?.data.records[0]?.elements
  //     );
  //     let res =
  //       response.data.data?.response?.results[0]?.data.records[0]?.elements;
  //     setLoc(res?.Strasse);
  //     setCity(res?.Ort);
  //     setZip(res?.Plz);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     //   setLoading(false);
  //   }
  // };

  const fetchLikeProperties = useCallback(async () => {
    const API_URL = "https://www.sae-mallorca.com/propertywebsite/api/proxy";
    const requestData = {
      token: "01f760ab735718c491feab1b732d06f3",
      request: {
        actions: [
          {
            actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
            resourceid: "",
            timestamp: timestamp,
            hmac_version: "2",
            hmac: hmacEstate,
            identifier: "",
            resourcetype: "estate",
            parameters: {
              data: [
                "Id", // Property id (**)
                "kaufpreis", // Price (**)
                "lage", // location / Address
                "objektart",
                "objekttyp",
                "objektnr_extern", // ref id (**)
                "objektnr_intern",
                "anzahl_badezimmer", // no of bathroom (**)
                "anzahl_zimmer", // no of rooms (**)
                "anzahl_schlafzimmer", // no of bedrooms (**)
                "nutzflaeche", // useable area(**)
                "verfuegbar", // Available Area
                "wohnflaeche", // Living space (**)
                "gesamtflaeche", // Total area
                "ort", // Property short location (**)
                "objekttitel", // Property title (**)
                "erstellt_am", // created date (**)
                "edi_kaufpreis",
                "virtualPrice",
                "calculatedPrice",
                "sonstige_angaben",
                "ausstatt_beschr", // Furnishing
                "objektbeschreibung", // property description
                "sonstige_angaben", // other info
                "heizungsart", // heating type
                "befeuerung", // firing
                "breitengrad", // lat
                "laengengrad", // long
                "terrasse", // Terrace
                "balkon", // Balcony
                "warmwasserEnthalten", // hot water
                "toiletten", // toilet
                "grundstuecksflaeche",
              ],
              filter: {
                veroeffentlichen: [{ op: "IN", val: "1" }],
                kaufpreis: [{ op: ">=", val: "1000.00" }],
                Id: [{ op: "!=", val: propertyId }],
                reserviert: [{ op: "=", val: 0 }],
                verkauft: [{ op: "=", val: 0 }],
                // "kaufpreis": [{"op": ">", "val": property.kaufpreis}]
              },
              // georangesearch: {"latitude": location.state.property?.elements.breitengrad, "longitude": location.state.property?.elements.laengengrad, "radius": "5500" },
              listlimit: 3,
              // listoffset: ,
              // "sortby": {"kaufpreis": "DESC"},
              estatelanguage: "ENG",
              addestatelanguage: true,
            },
          },
        ],
      },
    };
    try {
      const response = await axios.post(API_URL, requestData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      // // console.log(
      //   "INITIAL RESPONSE IDs",
      //   response.data.data?.response?.results[0]?.data.records
      // );

      let IDS = response.data.data?.response?.results[0]?.data?.records.map(
        (item) => parseInt(item.elements.Id)
      );
      // // console.log("ID ====", IDS);
      fetchAllPropertyListEstateImages(
        "ENG",
        IDS,
        response.data.data?.response?.results[0]?.data.records
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  }, [token]);

  const fetchLikePropertiesESP = useCallback(async () => {
    const API_URL = "https://www.sae-mallorca.com/propertywebsite/api/proxy";
    const requestData = {
      token: "01f760ab735718c491feab1b732d06f3",
      request: {
        actions: [
          {
            actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
            resourceid: "",
            timestamp: timestamp,
            hmac_version: "2",
            hmac: hmacEstate,
            identifier: "",
            resourcetype: "estate",
            parameters: {
              data: [
                "Id", // Property id (**)
                "kaufpreis", // Price (**)
                "lage", // location / Address
                "objektart",
                "objekttyp",
                "objektnr_extern", // ref id (**)
                "objektnr_intern",
                "anzahl_badezimmer", // no of bathroom (**)
                "anzahl_zimmer", // no of rooms (**)
                "anzahl_schlafzimmer", // no of bedrooms (**)
                "nutzflaeche", // useable area(**)
                "verfuegbar", // Available Area
                "wohnflaeche", // Living space (**)
                "gesamtflaeche", // Total area
                "ort", // Property short location (**)
                "objekttitel", // Property title (**)
                "erstellt_am", // created date (**)
                "edi_kaufpreis",
                "virtualPrice",
                "calculatedPrice",
                "sonstige_angaben",
                "ausstatt_beschr", // Furnishing
                "objektbeschreibung", // property description
                "sonstige_angaben", // other info
                "heizungsart", // heating type
                "befeuerung", // firing
                "breitengrad", // lat
                "laengengrad", // long
                "terrasse", // Terrace
                "balkon", // Balcony
                "warmwasserEnthalten", // hot water
                "toiletten", // toilet
                "grundstuecksflaeche",
              ],
              filter: {
                veroeffentlichen: [{ op: "IN", val: "1" }],
                kaufpreis: [{ op: ">=", val: "1000.00" }],
                Id: [{ op: "!=", val: propertyId }],
                reserviert: [{ op: "=", val: 0 }],
                verkauft: [{ op: "=", val: 0 }],
                // "kaufpreis": [{"op": ">", "val": property.kaufpreis}]
              },
              // georangesearch: {"latitude": location.state.property?.elements.breitengrad, "longitude": location.state.property?.elements.laengengrad, "radius": "5500" },
              listlimit: 3,
              // listoffset: ,
              // "sortby": {"kaufpreis": "DESC"},
              estatelanguage: "ESP",
              addestatelanguage: true,
            },
          },
        ],
      },
    };
    try {
      const response = await axios.post(API_URL, requestData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      // // console.log(
      //   "INITIAL RESPONSE IDs",
      //   response.data.data?.response?.results[0]?.data.records
      // );

      let IDS = response.data.data?.response?.results[0]?.data?.records.map(
        (item) => parseInt(item.elements.Id)
      );
      // // console.log("ID ====", IDS);
      fetchAllPropertyListEstateImages(
        "ESP",
        IDS,
        response.data.data?.response?.results[0]?.data.records
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  }, [token]);

  const fetchLikePropertiesGER = useCallback(async () => {
    const API_URL = "https://www.sae-mallorca.com/propertywebsite/api/proxy";
    const requestData = {
      token: "01f760ab735718c491feab1b732d06f3",
      request: {
        actions: [
          {
            actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
            resourceid: "",
            timestamp: timestamp,
            hmac_version: "2",
            hmac: hmacEstate,
            identifier: "",
            resourcetype: "estate",
            parameters: {
              data: [
                "Id", // Property id (**)
                "kaufpreis", // Price (**)
                "lage", // location / Address
                "objektart",
                "objekttyp",
                "objektnr_extern", // ref id (**)
                "objektnr_intern",
                "anzahl_badezimmer", // no of bathroom (**)
                "anzahl_zimmer", // no of rooms (**)
                "anzahl_schlafzimmer", // no of bedrooms (**)
                "nutzflaeche", // useable area(**)
                "verfuegbar", // Available Area
                "wohnflaeche", // Living space (**)
                "gesamtflaeche", // Total area
                "ort", // Property short location (**)
                "objekttitel", // Property title (**)
                "erstellt_am", // created date (**)
                "edi_kaufpreis",
                "virtualPrice",
                "calculatedPrice",
                "sonstige_angaben",
                "ausstatt_beschr", // Furnishing
                "objektbeschreibung", // property description
                "sonstige_angaben", // other info
                "heizungsart", // heating type
                "befeuerung", // firing
                "breitengrad", // lat
                "laengengrad", // long
                "terrasse", // Terrace
                "balkon", // Balcony
                "warmwasserEnthalten", // hot water
                "toiletten", // toilet
                "grundstuecksflaeche",
              ],
              filter: {
                veroeffentlichen: [{ op: "IN", val: "1" }],
                kaufpreis: [{ op: ">=", val: "1000.00" }],
                Id: [{ op: "!=", val: propertyId }],
                reserviert: [{ op: "=", val: 0 }],
                verkauft: [{ op: "=", val: 0 }],
                // "kaufpreis": [{"op": ">", "val": property.kaufpreis}]
              },
              // georangesearch: {"latitude": location.state.property?.elements.breitengrad, "longitude": location.state.property?.elements.laengengrad, "radius": "5500" },
              listlimit: 3,
              // listoffset: ,
              // "sortby": {"kaufpreis": "DESC"},
              estatelanguage: "",
              addestatelanguage: true,
            },
          },
        ],
      },
    };
    try {
      const response = await axios.post(API_URL, requestData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      // // console.log(
      //   "INITIAL RESPONSE IDs",
      //   response.data.data?.response?.results[0]?.data.records
      // );

      let IDS = response.data.data?.response?.results[0]?.data?.records.map(
        (item) => parseInt(item.elements.Id)
      );
      // // console.log("ID ====", IDS);
      fetchAllPropertyListEstateImages(
        "",
        IDS,
        response.data.data?.response?.results[0]?.data.records
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  }, [token]);

  const resourceTypeImages = "estatepictures";
  const actionIdImages = "urn:onoffice-de-ns:smart:2.5:smartml:action:get";

  const hmacImages = createHmac(
    token,
    secret,
    timestamp,
    resourceTypeImages,
    actionIdImages
  );
  // // console.log("hmac Image:", hmacImages);

  const fetchAllPropertyListEstateImages = useCallback(
    async (lang, ids, content) => {
      // setLoading(true);
      const API_URL = "https://www.sae-mallorca.com/propertywebsite/api/proxy";
      const requestData = {
        token: "01f760ab735718c491feab1b732d06f3",
        request: {
          actions: [
            {
              actionid: actionIdImages,
              resourceid: "",
              timestamp: timestamp,
              hmac_version: "2",
              hmac: hmacImages,
              identifier: "",
              resourcetype: resourceTypeImages,
              parameters: {
                estateids: ids,
                categories: ["Titelbild", "Foto"],
                size: "400X700",
                language: "",
              },
            },
          ],
        },
      };

      try {
        const response = await axios.post(API_URL, requestData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        // // console.log(
        //   "Images",
        //   response.data.data?.response?.results[0]?.data.records
        // );
        if (lang === "ENG") {
          let DATA = content.map((estate) => {
            const matchingFiles =
              response.data.data?.response?.results[0]?.data.records.flatMap(
                (file) =>
                  file.elements.filter(
                    (el) => el.estateid == estate.elements.Id
                  )
              );
            const titles = matchingFiles.map((file) => file.title);
            const urls = matchingFiles.map((file) => ({ url: file.url }));
            return {
              ...estate,
              elements: {
                ...estate.elements,
                titles,
                urls,
              },
            };
          });

          // // console.log("DAta with Image ENG", DATA);
          setLikeProperties(DATA);
        } else if (lang === "ESP") {
          let DATA = content.map((estate) => {
            const matchingFiles =
              response.data.data?.response?.results[0]?.data.records.flatMap(
                (file) =>
                  file.elements.filter(
                    (el) => el.estateid == estate.elements.Id
                  )
              );
            const titles = matchingFiles.map((file) => file.title);
            const urls = matchingFiles.map((file) => ({ url: file.url }));
            return {
              ...estate,
              elements: {
                ...estate.elements,
                titles,
                urls,
              },
            };
          });

          // // console.log("DAta with Image ESP", DATA);
          setLikePropertiesESP(DATA);
        } else if (lang === "") {
          let DATA = content.map((estate) => {
            const matchingFiles =
              response.data.data?.response?.results[0]?.data.records.flatMap(
                (file) =>
                  file.elements.filter(
                    (el) => el.estateid == estate.elements.Id
                  )
              );
            const titles = matchingFiles.map((file) => file.title);
            const urls = matchingFiles.map((file) => ({ url: file.url }));
            return {
              ...estate,
              elements: {
                ...estate.elements,
                titles,
                urls,
              },
            };
          });

          // // console.log("DAta with Image GER", DATA);
          setLikePropertiesGER(DATA);
        }

        // setAllData(DATA);
        // if (location.state) {
        //   const { maximumPrice, minimumPrice, filteredLocations, typeOfProperty, numberOfBed } = location.state;
        //   filterData(maximumPrice, minimumPrice, filteredLocations, typeOfProperty, numberOfBed);
        // } else {
        // // console.log("FILTERED LOCATIOn", typeOfProperty);
        //
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // setLoading(false);
      }
    },
    [token]
  );

  const resourceTypeAddress = "address";
  const actionIdAddress = "urn:onoffice-de-ns:smart:2.5:smartml:action:read";

  const hmacAddress = createHmac(
    token,
    secret,
    timestamp,
    resourceTypeAddress,
    actionIdAddress
  );
  // // console.log("hmac Address:::::", hmacAddress);

  const formatPrice = (price) => {
    const locale = i18n.language.startsWith("en") ? "en-US" : "de-DE";
    return new Intl.NumberFormat(locale, {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const navigateToPropertyDetails = () => {
    setTimeout(() => {
      history.push({
        pathname: "/propertysearch",
        state: {
          maximumPrice: "10000000",
          minimumPrice: "1000000",
        },
      });
    }, 1500);
  };

  const resourceTypePDF = "pdf";
  const actionIdPDF = "urn:onoffice-de-ns:smart:2.5:smartml:action:get";

  const hmacPDF = createHmac(
    token,
    secret,
    timestamp,
    resourceTypePDF,
    actionIdPDF
  );
  // // console.log("hmac PDF:::::", hmacPDF);

  const getPdf = useCallback(
    async (id, addressId) => {
      setLoading(true);
      const API_URL = "https://www.sae-mallorca.com/propertywebsite/api/proxy";
      const requestData = {
        token: "01f760ab735718c491feab1b732d06f3",
        request: {
          actions: [
            {
              actionid: actionIdPDF,
              timestamp: timestamp,
              hmac_version: "2",
              hmac: hmacPDF,
              resourceid: "",
              identifier: "",
              resourcetype: "pdf",
              parameters: {
                estateid: id,
                template:
                  "urn:onoffice-de-ns:smart:2.5:pdf:expose:lang:expose_1",
                // template:"urn:onoffice-de-ns:smart:2.5:pdf:expose:lang:Exposé Wayne",
                // urn:onoffice-de-ns:smart:2.5:pdf:expose:lang:design03Expose
                // urn:onoffice-de-ns:smart:2.5:pdf:expose:lang:Exposé Alba
                // urn:onoffice-de-ns:smart:2.5:pdf:expose:lang:Exposé Electra
                forceEstateLocation: "true",
                gzcompress: "true",
                addressids: addressId,
              },
            },
          ],
        },
      };
      try {
        const response = await axios.post(API_URL, requestData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        const base64EncodedCompressedData =
          response.data.data?.response?.results[0]?.data?.records[0]?.elements
            ?.document;

        const compressedBinaryString = atob(base64EncodedCompressedData);

        // Convert the decoded string to a Uint8Array
        const compressedBinaryArray = new Uint8Array(
          compressedBinaryString.length
        );
        for (let i = 0; i < compressedBinaryString.length; i++) {
          compressedBinaryArray[i] = compressedBinaryString.charCodeAt(i);
        }

        const decompressedData = pako.ungzip(compressedBinaryArray);

        // Convert the decompressed data to a base64 string
        const decompressedBase64 = btoa(
          decompressedData.reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        // Create a Blob from the decompressed data
        const blob = new Blob([decompressedData], { type: "application/pdf" });

        const url = URL.createObjectURL(blob);

        // // console.log(url);
        // // console.log(imgUrl);
        // if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        //   // For mobile browsers, open in a new tab
        //   window.open(url, "_blank");
        // } else {
        //   // For desktop, set the PDF URL directly
        //   setPdfUrl(url);
        // }

        // if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        //   setShowIOS(true)// Trigger download if viewing fails
        // } else {
        // setShowIOS(false)
        // }
        setPdfUrl(url);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    },
    [token]
  );
  const handlePdfDownload = (url) => {
    window.open(url, "_blank");
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      // Fallback to iframe for iOS Safari if window.open doesn't work
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 5000); // Remove iframe after a short delay
    } else {
      // For other browsers
      const link = document.createElement("a");
      link.href = url;
      link.download = `${objektnr_extern}.pdf`; // Customize file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setShow(false);
  };

  useEffect(() => {
    if (show && /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      if (pdfUrl !== "" && loading == false) {
        // handlePdfDownload(pdfUrl)
        setShowIOS(true);
      }
    }
  }, [show, pdfUrl, loading]);

  const resourceTypeTemplate = "templates";
  const actionIdTemplate = "urn:onoffice-de-ns:smart:2.5:smartml:action:get";

  const hmacTemplate = createHmac(
    token,
    secret,
    timestamp,
    resourceTypeTemplate,
    actionIdTemplate
  );
  // // console.log("hmac Template:::::", hmacTemplate);
  const tokens =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
  const submitBookings = async (
    salutation,
    firstName,
    lastName,
    email,
    phone,
    message,
    prop_img
  ) => {
    const API_URL =
      "https://www.sae-mallorca.com/propertywebsite/api/property-messages";
    let reqData = {
      data: {
        property_id: propertyId, // 4
        property_name: objekttitel, // 6
        property_location: ort, // 5
        property_reference_id: objektnr_extern, // 3
        property_price: formatPrice(kaufpreis), // 2
        // property_images: property.elements.urls, // 1
        property_number_of_bathrooms:
          anzahl_badezimmer === ""
            ? "NIL"
            : JSON.stringify(parseInt(anzahl_badezimmer)), // 7
        property_number_of_rooms:
          anzahl_zimmer === ""
            ? "NIL"
            : JSON.stringify(parseInt(anzahl_zimmer)), // 8
        property_available_area:
          wohnflaeche === "" ? "NIL" : `${parseInt(wohnflaeche)}㎡`, // 9
        property_usable_area:
          nutzflaeche === "" ? "NIL" : `${parseInt(nutzflaeche)}㎡`, // 10

        salutation: salutation,
        first_name: firstName,
        last_name: lastName,
        email: email.trim(),
        phone: phone,
        message: message,
        property_images: JSON.stringify(prop_img),
      },
    };
    try {
      const response = await axios.post(API_URL, reqData, {
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${tokens}`,
        },
      });
      // // console.log("RESPONSE ====>", response);
      if (response.status == 200) {
        toast.success(t("propertyReserved"));
        history.push("/");
      } else {
        toast.error(t("errorOccurred"));
      }
    } catch (error) {
      console.error("Error fetching data:", error.response);
      toast.error(t("errorOccurred"));
    }
  };

  // useEffect(() => {
  //   fetchData(property.id);
  //   fetchLikeProperties();
  //   fetchLikePropertiesESP();
  //   fetchLikePropertiesGER();
  //   // setTimeout(() => {

  //   // }, 1000);
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, [location?.state?.property]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [mainImage, setMainImage] = useState(null);

  const additionalSwiperRef = useRef(null);

  const slidesPerView = 5;

  const handlePrevClick = () => {
    const prevIndex = (currentIndex - 1 + allImage.length) % allImage.length;
    setCurrentIndex(prevIndex);
    setMainImage(allImage[prevIndex]?.url);
    updateAdditionalSwiper(prevIndex);
    updatePagination(prevIndex);
  };

  const handleNextClick = () => {
    const nextIndex = (currentIndex + 1) % allImage.length;
    setCurrentIndex(nextIndex);
    setMainImage(allImage[nextIndex]?.url);
    updateAdditionalSwiper(nextIndex);
    updatePagination(nextIndex);
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
    setMainImage(allImage[index]?.url);
    updateAdditionalSwiper(index);
    updatePagination(index);
  };

  const handleDotClick = (dotIndex) => {
    const newIndex = dotIndex;
    setCurrentIndex(newIndex);
    setMainImage(allImage[newIndex]?.url);
    updateAdditionalSwiper(newIndex);
    updatePagination(newIndex);
  };

  const updateAdditionalSwiper = (index) => {
    const activeSlideIndex = Math.floor(index);
    if (additionalSwiperRef.current && additionalSwiperRef.current.swiper) {
      additionalSwiperRef.current.swiper.slideTo(activeSlideIndex);
    }
  };

  const updatePagination = (index) => {
    const activeDotIndex = index;
    const bullets = document.querySelectorAll(".pagination-dots .dot");
    bullets.forEach((bullet, i) => {
      if (i === activeDotIndex) {
        bullet.classList.add("active");
      } else {
        bullet.classList.remove("active");
      }
    });
  };

  useEffect(() => {
    updateAdditionalSwiper(currentIndex);
    updatePagination(currentIndex);
  }, [currentIndex]);

  const totalPaginationDots = Math.max(allImage?.length, 0);

  const saveFavorite = () => {
    const engProperty = engProp.find(
      (data) => data.elements.objektnr_extern === objektnr_extern
    );
    const gerProperty = gerProp.find(
      (data) => data.elements.objektnr_extern === objektnr_extern
    );
    const espProperty = espProp.find(
      (data) => data.elements.objektnr_extern === objektnr_extern
    );
    // // console.log(lastSegment.replace(/-/g, " "));

    toggleFavorite(engProperty, espProperty, gerProperty);
  };
  // const isFavorite = favorites.some((fav) => fav.id === property.id);
  const isFavorite =
    favorites.some(
      (fav) => fav?.elements?.objektnr_extern === lastSegment.replace(/-/g, " ")
    ) ||
    espFavorites.some(
      (fav) => fav?.elements?.objektnr_extern === lastSegment.replace(/-/g, " ")
    ) ||
    gerFavorites.some(
      (fav) => fav?.elements?.objektnr_extern === lastSegment.replace(/-/g, " ")
    );

  // const isFavorite = (item) =>
  //   favorites.some(
  //     (fav) => fav.elements.objektnr_extern === item.elements.objektnr_extern
  //   ) ||
  //   espFavorites.some(
  //     (fav) => fav.elements.objektnr_extern === item.elements.objektnr_extern
  //   ) ||
  //   gerFavorites.some(
  //     (fav) => fav.elements.objektnr_extern === item.elements.objektnr_extern
  //   );

  const handleToggleFavorite = (eng, spa, ger) => {
    // console.log(eng);
    // console.log(spa);
    // console.log(ger);
  };
  return (
    <>
      <Helmet>
        <meta itemprop="inLanguage" content="de" />
        <meta
          name="description"
          itemprop="description"
          content={objektbeschreibung}
        />

        <link
          rel="canonical"
          href={`https://www.sae-mallorca.com${location.pathname}`}
        />
        <link
          rel="alternate"
          href={`https://www.sae-mallorca.com${location.pathname}`}
          hreflang="de"
        />
        <link
          rel="alternate"
          href={`https://www.sae-mallorca.com${location.pathname}`}
          hreflang="en"
        />
        <link
          rel="alternate"
          href={`https://www.sae-mallorca.com${location.pathname}`}
          hreflang="es"
        />
        <link rel="icon" href={`${mainImage}`} />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={objekttitel} />
        <meta property="og:image" content={`${mainImage}`} />
        <meta property="og:description" content={objektbeschreibung} />
        <meta
          property="og:url"
          content={`https://www.sae-mallorca.com${location.pathname}`}
        />
        <meta
          property="og:site_name"
          content="Immobilien Mallorca kaufen |S.A.E Real Estate Mallorca"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content={objektbeschreibung} />
        <meta name="twitter:title" content={objekttitel} />
      </Helmet>
      {sold == "1" || reserved == "1" || publish == "0" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              gap: "10px", // optional spacing between items
            }}
          >
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6hiUXe2rIbgmGfk3r7YM8a14Pv-3YjN-6jQ&s"
              alt="No Properties"
            />
            <span style={{}}>{t("noProperties")}</span>
          </div>
        </div>
      ) : propLoading ? (
        <>
          <div className="addprod_view">
            <div className="c_6 auto">
              <div className="row spaced">
                <div className="estate_slider">
                  <div className="slide">
                    <div className="c_slider slider-active slider-mouse">
                      <div className="sliderStyle">
                        <button
                          className="nav-button prev-button"
                          onClick={handlePrevClick}
                        >
                          «
                        </button>
                        <LazyLoadImage
                          // className="main-image"
                          alt=""
                          title=""
                          src={mainImage}
                          effect="blur"
                          style={{
                            height: 400,
                            width: 700,
                            objectFit: "cover",
                          }}
                        />
                        {/* <ImageModal mainImage={mainImage}  handlePrevClick={handlePrevClick}
        handleNextClick={handleNextClick}/> */}
                        <button
                          className="nav-button next-button"
                          onClick={handleNextClick}
                        >
                          »
                        </button>
                      </div>
                      <div className="slider-thumbnail">
                        <Skeleton height={250} />
                      </div>
                      <div className="pagination-dots">
                        {Array.from({ length: totalPaginationDots }).map(
                          (_, idx) => (
                            <span
                              key={idx}
                              className={`dot ${
                                currentIndex === idx ? "active" : ""
                              }`}
                              onClick={() => handleDotClick(idx)}
                            ></span>
                          )
                        )}
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="slide_mini">
                    <div className="c_slider slider-active"></div>
                  </div>
                </div>
                <div className="details">
                  <div className="heading txt_2">
                    <Skeleton height={50} />
                  </div>
                  <div className="row keep spaced details2">
                    <div className="location">
                      <Skeleton height={50} />
                    </div>
                    <div>
                      <Skeleton height={50} />
                    </div>
                  </div>
                  <div className="row wrap spaced keep data">
                    <div className="center_v">
                      <img
                        alt="Schlafzimmer"
                        className="sprites"
                        src={property_pic1}
                      />
                      <div>
                        <Skeleton height={50} />
                      </div>
                    </div>
                    <div className="center_v">
                      <img
                        alt="Schlafzimmer"
                        className="sprites"
                        src={property_pic2}
                      />
                      <div>
                        <Skeleton height={50} />
                      </div>
                    </div>
                    <div className="center_v">
                      <img
                        alt="Schlafzimmer"
                        className="sprites"
                        src={property_pic3}
                      />
                      <div>
                        <Skeleton height={50} />
                      </div>
                    </div>
                    <div className="center_v">
                      <img
                        alt="Schlafzimmer"
                        className="sprites"
                        src={property_pic4}
                      />
                      <div>
                        <Skeleton height={50} />
                      </div>
                    </div>
                    <div className="center_v price_label">
                      <div>{t("salePrice")}</div>
                      <div className="price">
                        <Skeleton height={50} />
                      </div>
                    </div>
                  </div>
                  <div className="row actions">
                    <a
                      href="javascript:void(0)"
                      className={`c_1_1 light c_selected_marker ${
                        isFavorite ? "favorite" : ""
                      }`}
                      // onClick={() => saveFavorite()}
                    >
                      {isFavorite ? (
                        <>
                          <CheckIcon style={{ color: "#666973" }} />{" "}
                          {t("saved")}
                        </>
                      ) : (
                        t("remember")
                      )}
                    </a>
                    <a
                      href="javascript:void(0)"
                      // onClick={handleShow}
                      className="c_1_1 light expose"
                    >
                      {t("viewExpose")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="poperty_des">
            <div className="c_7">
              <div className="auto box row spaced">
                <Skeleton height={300} />
                <div>
                  <div className="row spaced grow box_contact">
                    <div>
                      <div className="heading txt_1">{t("contact")}</div>
                      <div className="row contact">
                        <div>
                          <div className="row keep phone">
                            {/* <img className="c_icon" src={phoneicon2} alt="Icon phone" /> */}
                            <img
                              className="c_icon2"
                              src={phoneicon1}
                              alt="Icon phone"
                            />

                            <Skeleton height={100} />
                          </div>
                          <div className="row keep phone">
                            {/* <img className="c_icon" src={phoneicon2} alt="Icon phone_mobil" /> */}
                            <img
                              className="c_icon2"
                              src={phoneicon2}
                              alt="Icon phone"
                            />

                            <a href="tel:+34604114919">+34 604 114 919</a>
                          </div>
                          <div className="row keep phone">
                            {/* <img className="c_icon" src="https://www.sae-mallorca.com/assets/img/sprites.svg#phone_mobil" alt="Icon phone_mobil" /> */}
                            <img
                              className="c_icon2"
                              src={phoneicon2}
                              alt="Icon phone"
                            />

                            <Skeleton height={100} />
                          </div>
                        </div>
                        <div>
                          <Skeleton height={100} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cm_form">
                    <div className="auto">
                      <div className="heading">{t("cForm")}</div>
                      <Skeleton width={200} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="poperty_des_moreinfo">
            <div className="auto c_8">
              <div className="heading txt_2">{t("features")}</div>
              <div className="row wrap">
                <div className="box">
                  <div className="label">{t("heatingType")}</div>
                  <p className="body">
                    {heizungsart?.length == 0 ? "nA" : heizungsart[0]}
                  </p>
                </div>
                <div className="box">
                  <div className="label">{t("fuelType")}</div>
                  <p className="body">
                    {befeuerung?.length == 0 ? "nA" : befeuerung[0]}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Skeleton width={400} height={200} />
          <section
            className="immobilieVerkaufen nopadding"
            onClick={navigateToPropertyDetails}
          >
            <div className="cm_6">
              <div className="row">
                <div className="pic">
                  <img src={luxusimmobilienadd1} alt="" title="" />
                </div>
                <div className="box">
                  <Skeleton width={400} height={200} />
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <div className="addprod_view">
            <div className="c_6 auto">
              <div className="row spaced">
                <div className="estate_slider">
                  <div className="slide">
                    <div className="c_slider slider-active slider-mouse">
                      <div className="sliderStyle">
                        <button
                          className="nav-button prev-button"
                          onClick={handlePrevClick}
                        >
                          «
                        </button>
                        {/* <LazyLoadImage
                      // className="main-image"
                      alt=""
                      title=""
                      src={mainImage}
                      effect="blur"
                      style={{ height: 400, width: 700, objectFit: "cover" }}
                    /> */}
                        <ImageModal
                          mainImage={mainImage}
                          handlePrevClick={handlePrevClick}
                          handleNextClick={handleNextClick}
                        />
                        <button
                          className="nav-button next-button"
                          onClick={handleNextClick}
                        >
                          »
                        </button>
                      </div>
                      <div className="slider-thumbnail">
                        <Swiper
                          ref={additionalSwiperRef}
                          spaceBetween={30}
                          slidesPerView={5}
                          // modules={[Navigation, Pagination]}
                          // slidesPerView={7}
                          // pagination={{ clickable: true, type: 'bullets' }}

                          // onSlideChange={(swiper) => {
                          //   swiper.pagination.update();
                          //   swiper.pagination.render();
                          // }}

                          // style={{
                          //   "--swiper-pagination-color": "#54595F",
                          //   "--swiper-pagination-bullet-inactive-color": "#999999",
                          //   "--swiper-pagination-bullet-inactive-opacity": "0.5",
                          // }}
                        >
                          {allImage?.map((image, index) => (
                            <SwiperSlide key={index}>
                              <div
                                // className="thumb-item"
                                onClick={() => handleThumbnailClick(index)}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  className=""
                                  alt=""
                                  title=""
                                  src={image.url}
                                  style={{ height: 70 }}
                                />
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                      <div className="pagination-dots">
                        {Array.from({ length: totalPaginationDots }).map(
                          (_, idx) => (
                            <span
                              key={idx}
                              style={{ display: "flex", flexWrap: "wrap" }}
                              className={` ${
                                currentIndex === idx ? "dot active" : "dot"
                              }`}
                              onClick={() => handleDotClick(idx)}
                            ></span>
                          )
                        )}
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="slide_mini">
                    <div className="c_slider slider-active"></div>
                  </div>
                </div>
                <div className="details">
                  <div className="heading txt_2">
                    {objekttitel === "" ? "Not Set" : objekttitel}
                  </div>
                  <div className="row keep spaced details2">
                    <div className="location">{ort === "" ? "nA" : ort}</div>
                    <div>
                      Ref: {objektnr_extern === "" ? "nA" : objektnr_extern}
                    </div>
                  </div>
                  <div className="row wrap spaced keep data">
                    <div className="center_v">
                      <img
                        alt="Schlafzimmer"
                        className="sprites"
                        src={property_pic1}
                      />
                      <div>
                        {anzahl_zimmer === ""
                          ? "not set"
                          : parseInt(anzahl_zimmer)}
                      </div>
                    </div>
                    <div className="center_v">
                      <img
                        alt="Schlafzimmer"
                        className="sprites"
                        src={property_pic2}
                      />
                      <div>
                        {anzahl_badezimmer === ""
                          ? "not set"
                          : parseInt(anzahl_badezimmer)}
                      </div>
                    </div>
                    <div className="center_v">
                      <img
                        alt="Schlafzimmer"
                        className="sprites"
                        src={property_pic3}
                      />
                      <div>
                        {wohnflaeche === ""
                          ? "not set"
                          : `${parseInt(wohnflaeche)}㎡`}
                      </div>
                    </div>
                    <div className="center_v">
                      <img
                        alt="Schlafzimmer"
                        className="sprites"
                        src={property_pic4}
                      />
                      <div>
                        {nutzflaeche === ""
                          ? "not set"
                          : `${parseInt(nutzflaeche)}㎡`}
                      </div>
                    </div>
                    <div className="center_v price_label">
                      <div>{t("salePrice")}</div>
                      <div className="price">€ {formatPrice(kaufpreis)}</div>
                    </div>
                  </div>
                  <div className="row actions">
                    <a
                      href="javascript:void(0)"
                      className={`c_1_1 light c_selected_marker ${
                        isFavorite ? "favorite" : ""
                      }`}
                      onClick={() => saveFavorite()}
                    >
                      {isFavorite ? (
                        <>
                          <CheckIcon style={{ color: "#666973" }} />{" "}
                          {t("saved")}
                        </>
                      ) : (
                        t("remember")
                      )}
                    </a>
                    <a
                      href="javascript:void(0)"
                      onClick={handleShow}
                      className="c_1_1 light expose"
                    >
                      {t("viewExpose")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="poperty_des">
            <div className="c_7">
              <div className="auto box row spaced">
                <div>
                  <div className="heading txt_1">{t("description")}</div>
                  <div className="body">
                    <p>{objektbeschreibung}</p>
                  </div>
                  {lage === "" ? null : (
                    <div className="body" style={{ marginTop: 15 }}>
                      <span className="heading txt_1">
                        {t("locationDetails")}
                      </span>
                      <span>
                        <p>{lage}</p>
                      </span>
                    </div>
                  )}
                  {ausstatt_beschr === "" ? null : (
                    <div className="body" style={{ marginTop: 15 }}>
                      <span className="heading txt_1">{t("equipment")}</span>
                      <span>
                        <p>{ausstatt_beschr}</p>
                      </span>
                    </div>
                  )}
                  {sonstige_angaben === "" ? null : (
                    <div className="body" style={{ marginTop: 15 }}>
                      <span className="heading txt_1">{t("amenities")}</span>
                      <span>
                        <p>{sonstige_angaben}</p>
                      </span>
                    </div>
                  )}

                  <div className="body"></div>
                  {balkon == 1 ||
                  terrasse == 1 ||
                  warmwasserEnthalten == 1 ||
                  toiletten == 1 ? (
                    <div className="features c_11">
                      <div className="heading txt_1" style={{ marginTop: 15 }}>
                        {t("feature")}
                      </div>
                      <ul className="row wrap">
                        {terrasse == 1 ? (
                          <li
                            className="row keep start"
                            style={{ marginRight: 10 }}
                          >
                            <CheckIcon className="c_icon" />
                            <span>{t("terrace")}</span>
                          </li>
                        ) : null}
                        {balkon == 1 ? (
                          <li
                            className="row keep start"
                            style={{ marginRight: 10 }}
                          >
                            <CheckIcon className="c_icon" />
                            <span>{t("balcony")}</span>
                          </li>
                        ) : null}
                        {warmwasserEnthalten == 1 ? (
                          <li
                            className="row keep start"
                            style={{ marginRight: 10 }}
                          >
                            <CheckIcon className="c_icon" />
                            <span>{t("hotWater")}</span>
                          </li>
                        ) : null}
                        {toiletten == 1 ? (
                          <li
                            className="row keep start"
                            style={{ marginRight: 10 }}
                          >
                            <CheckIcon className="c_icon" />
                            <span>{t("disabledToilet")}</span>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  ) : null}
                </div>
                <div>
                  <div className="row spaced grow box_contact">
                    <div>
                      <div className="heading txt_1">{t("contact")}</div>
                      <div className="row contact">
                        <div>
                          <div className="row keep phone">
                            {/* <img className="c_icon" src={phoneicon2} alt="Icon phone" /> */}
                            <img
                              className="c_icon2"
                              src={phoneicon1}
                              alt="Icon phone"
                            />

                            <a href="tel:+34971418972">+34 971 418 972</a>
                          </div>
                          <div className="row keep phone">
                            {/* <img className="c_icon" src={phoneicon2} alt="Icon phone_mobil" /> */}
                            <img
                              className="c_icon2"
                              src={phoneicon2}
                              alt="Icon phone"
                            />

                            <a href="tel:+34604114919">+34 604 114 919</a>
                          </div>
                          <div className="row keep phone">
                            {/* <img className="c_icon" src="https://www.sae-mallorca.com/assets/img/sprites.svg#phone_mobil" alt="Icon phone_mobil" /> */}
                            <img
                              className="c_icon2"
                              src={phoneicon2}
                              alt="Icon phone"
                            />

                            <a href="tel:+34603305186">+34 603 305 186</a>
                          </div>
                        </div>
                        <div>
                          <p>
                            <span></span>
                            <span itemprop="address">
                              <span itemprop="streetAddress">{loc}</span>
                              {/* <span itemprop="postalCode">07199 </span> */}
                              <div
                                itemprop="addressLocality"
                                style={{ marginTop: 10 }}
                              >
                                {zip} {city}
                              </div>
                            </span>
                            <span itemprop="image" className="hide">
                              https://www.sae-mallorca.com/assets/img/logo.png
                            </span>
                            <span itemprop="url" className="hide">
                              https://www.sae-mallorca.com
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cm_form">
                    <div className="auto">
                      <div className="heading">{t("cForm")}</div>
                      <form name="form_free" className="c_form2">
                        <Box component="form" noValidate autoComplete="off">
                          <div className="inp_row_block_wrap">
                            <div className="inp_row_block static-width">
                              <TextField
                                id="outlined-salutation"
                                select
                                label={t("salutation")}
                                value={salutation}
                                onChange={(e) => setSalutation(e.target.value)}
                                error={error.field === "salutation"}
                                helperText={
                                  error.field === "salutation" && error.message
                                }
                                // variant="outlined"
                                // fullWidth
                                // sx={{width:150}}
                              >
                                {salutations.map((option, i) => (
                                  <MenuItem key={i} value={option.value}>
                                    {t(option.value)}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                            <div className="inp_row_block static-width">
                              <TextField
                                id="outlined-first-name"
                                label={t("firstName")}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                error={error.field === "firstName"}
                                helperText={
                                  error.field === "firstName" && error.message
                                }
                              />
                            </div>
                            <div className="inp_row_block">
                              <TextField
                                id="outlined-last-name"
                                label={t("lastName")}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                error={error.field === "lastName"}
                                helperText={
                                  error.field === "lastName" && error.message
                                }
                              />
                            </div>
                          </div>
                          <div className="inp_row_block_wrap">
                            <div className="inp_row_block">
                              <TextField
                                label={t("yourEmail")}
                                id="outlined-email"
                                defaultValue=""
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={error.field === "email"}
                                helperText={
                                  error.field === "email" && error.message
                                }
                              />
                            </div>
                            <div className="inp_row_block">
                              <TextField
                                label={t("phone")}
                                id="outlined-telefon"
                                defaultValue=""
                                value={phone}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^[\d+]*$/.test(value)) {
                                    setPhone(value);
                                  }
                                }}
                                error={error.field === "phone"}
                                helperText={
                                  error.field === "phone" && error.message
                                }
                              />
                            </div>
                          </div>
                          <div className="inp_row_block textarea_info">
                            {/* <TextField
												id="outlined-textarea"
												label="Bitte geben Sie hier ihre Nachricht an uns ein"
												multiline
												rows={3}
												defaultValue=""
												variant="standard"
												/> */}

                            <TextField
                              label={t("pleaseEnterYourMessage")}
                              id="outlined-telefon"
                              defaultValue=""
                              multiline
                              rows={4}
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              error={error.field === "message"}
                              helperText={
                                error.field === "message" && error.message
                              }
                            />
                          </div>
                          <div className="inp_row_block">
                            {/* <FormGroup>
											<FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
											</FormGroup> */}
                            <div className="c_form_checkbox c_field">
                              <label className="" data-id="agreed">
                                <input
                                  type="checkbox"
                                  id="checkbox_agreed"
                                  data-id="agreed"
                                  checked={agreed}
                                  onChange={(e) => setAgreed(e.target.checked)}
                                  error={error.field === "agreed"}
                                />
                                {t("privacyPolicyText")}{" "}
                                <a href="/datenschutz" target="_blank">
                                  {t("privacyPolicyLinkText")}
                                </a>
                                *
                              </label>
                              {error.field === "agreed" && (
                                <p style={{ color: "red" }}>{error.message}</p>
                              )}
                            </div>
                          </div>

                          <div className="row two narrow end actions">
                            <button
                              type="submit"
                              className="button index_0 "
                              onClick={handleSubmit}
                            >
                              {t("submitButton")}{" "}
                            </button>
                          </div>
                        </Box>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="poperty_des_moreinfo">
            <div className="auto c_8">
              <div className="heading txt_2">{t("features")}</div>
              <div className="row wrap">
                <div className="box">
                  <div className="label">{t("heatingType")}</div>
                  <p className="body">
                    {heizungsart?.length == 0 ? "nA" : heizungsart[0]}
                  </p>
                </div>
                <div className="box">
                  <div className="label">{t("fuelType")}</div>
                  <p className="body">
                    {befeuerung?.length == 0 ? "nA" : befeuerung[0]}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FurtherInformation
            lat={breitengrad === undefined ? "" : breitengrad}
            long={laengengrad === undefined ? "" : laengengrad}
          />
          <section
            className="immobilieVerkaufen nopadding"
            onClick={navigateToPropertyDetails}
          >
            <div className="cm_6">
              <div className="row">
                <div className="pic">
                  <img src={luxusimmobilienadd1} alt="" title="" />
                </div>
                <div className="box">
                  <div className="txt_3">{t("luxuryProperties")}</div>
                  <div className="body">
                    <p>{t("luxuryPropertiesDescription")}</p>
                  </div>
                  <div className="c_1">{t("discoverNow")}</div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {sold !== "1" && reserved !== "1" && publish !== "0" && (
        <PopertiesLike
          engData={likeProperties}
          spaData={likePropertiesESP}
          gerData={likePropertiesGER}
        />
      )}
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Print Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="popupinfo">
            {loading ? (
              <p
                style={{
                  height: 500,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {t("loadingPDF")}
              </p>
            ) : showIOS ? (
              <div
                style={{
                  width: "100%",
                  // margin: "auto",
                  height: 250,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Button
                  variant="outlined"
                  onClick={() => handlePdfDownload(pdfUrl)}
                  sx={{
                    borderColor: "#65666A", // Border color
                    color: "#65666A", // Text color
                    backgroundColor: "white", // Set background to white so text/border is visible
                    border: "1px solid grey", // Ensures the border is visible
                    "&:hover": {
                      backgroundColor: "#fff", // Optional: Change background on hover
                      borderColor: "#CAA274",
                      color: "#CAA274",
                    },
                  }}
                >
                  {t("showPdf")}
                </Button>{" "}
              </div>
            ) : (
              <iframe
                // type="application/pdf"
                src={pdfUrl}
                title="PDF Viewer"
              ></iframe>
            )}
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
      <Snackbar
        open={!!error.field}
        autoHideDuration={6000}
        onClose={handleCloses}
      >
        <Alert onClose={handleClose} severity="error">
          {error.message}
        </Alert>
      </Snackbar>
    </>
    //  <div>
    //   kk
    //  </div>
  );
};

export default PopertyDescription;
