// VideoPlayer.js
import React from 'react';
import YouTube from 'react-youtube';

const VideoPlayer = ({ videoId, url }) => {
  // // console.log('VIDEO_ID', videoId)
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };
  const videoUrl = `https://www.youtube.com/watch?v=${videoId}`;
  const handleCopyLink = () => {
    navigator.clipboard.writeText(videoUrl).then(() => {
      alert('Video link copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  return    (<div>
  <YouTube videoId={videoId} opts={opts} />
  {/* <button style={{position:'absolute'}} onClick={handleCopyLink}>Copy Link</button> */}
</div>)
};

export default VideoPlayer;
