import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import property_pic1 from "../../assets/img/property_pic1.png";
import property_pic2 from "../../assets/img/property_pic2.png";
import property_pic3 from "../../assets/img/property_pic3.png";
import property_pic4 from "../../assets/img/property_pic4.png";

import Slider from "react-slick";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import useStore from "../../Store/useStore";
var slideopt1 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};


const TopRecommendations = ({ engData, spaData, gerData, loading }) => {
  const selectLanguage = useStore((state) => state.selectLanguage);
  const setSelectLanguage = useStore((state) => state.setSelectLanguage);
  // const topRecommendedProperty = useStore((state) => state.topRecommendedProperty);
  // const setTopRecommendedProperty = useStore((state) => state.setTopRecommendedProperty);
  // const topRecommendedPropertyESP = useStore((state) => state.topRecommendedPropertyESP);
  // const setTopRecommendedPropertyESP = useStore((state) => state.setTopRecommendedPropertyESP);
  // const topRecommendedPropertyGER = useStore((state) => state.topRecommendedPropertyGER);
  // const setTopRecommendedPropertyGER = useStore((state) => state.setTopRecommendedPropertyGER);
  // // console.log(engData);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState(gerData)

  const formatPrice = (price) => {
    const locale = i18n.language.startsWith('en') ?  'en-US' : 'de-DE' ;
      return new Intl.NumberFormat(locale, {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };
  
  const handleCardClick = (item, index) => {
    // console.log("selected", item);
    const engProperty = engData.find((data) => data.elements.objektnr_extern === item.elements.objektnr_extern);
    const gerProperty = gerData.find((data) => data.elements.objektnr_extern === item.elements.objektnr_extern);
    const espProperty = spaData.find((data) => data.elements.objektnr_extern === item.elements.objektnr_extern);
    // console.log("ENG",engProperty)
    // console.log("espData",espProperty)
    // console.log("gerData",gerProperty)
    
    const sanitizeString = (str) => {
      return str
                .replace(/\s+/g, '-') // Replace spaces with hyphens
                .replace(/[^\w-]+/g, ''); // Remove all non-word characters except hyphens
    };
  
    const sanitizedOrt = sanitizeString(item.elements.ort);
    const sanitizedObjektart = sanitizeString(item.elements.objektart);
    const refId = sanitizeString(item.elements.objektnr_extern)

    const dynamicUrl = `/propertydetails/${sanitizedOrt}/${item.id}-${sanitizedObjektart}/${refId}`;

    setTimeout(() => {
      history.push({
        pathname: dynamicUrl,
        state: { 
          property: engProperty, 
          gerProperty: gerProperty, 
          espProperty: espProperty 
        }
      });
    }, 1000);
  }
// useEffect(() => {
//   selectLanguage === "en" ? setData(engData) : selectLanguage === "es" ? setData(spaData) : setData(gerData)
// }, [])
useEffect(() => {
  selectLanguage === "en" ? setData(engData) : selectLanguage === "es" ? setData(spaData) : setData(gerData)
}, [selectLanguage, engData, spaData, gerData])
  const renderSkeleton = () => (
    <div className="c_5" style={{ marginLeft: "0.5vw" }}>
      <div className="c_slider">
        <div className="slides propertySerarchSlide">
          <div className="slides_wrap_info">
            <Skeleton height={300} />
          </div>
        </div>
      </div>
      <div className="box">
        <div className="row keep spaced short">
          <div className="location">
            <Skeleton width={80} />
          </div>
          <div>
            Ref: <Skeleton width={60} />
          </div>
        </div>
        <div className="heading txt_3">
          <Skeleton width={200} />
        </div>
        <div className="price">
          <Skeleton width={80} />
        </div>
        <div className="row grow keep details">
          <div className="center_v">
            <Skeleton width={50} height={50} circle={true} />
            <div>{t("bedrooms")}</div>
            <div>
              <Skeleton width={30} />
            </div>
          </div>
          <div className="center_v">
            <Skeleton width={50} height={50} circle={true} />
            <div>{t("bathrooms")}</div>
            <div>
              <Skeleton width={30} />
            </div>
          </div>
          <div className="center_v">
            <Skeleton width={50} height={50} circle={true} />
            <div>{t("livingArea")}</div>
            <div>
              <Skeleton width={30} />
            </div>
          </div>
          <div className="center_v">
            <Skeleton width={50} height={50} circle={true} />
            <div>{t("land")}</div>
            <div>
              <Skeleton width={30} />
            </div>
          </div>
        </div>
        <div className="row spaced clicks" style={{ paddingBottom: 15 }}>
          <Skeleton width={100} height={30} />
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <section className="aboutinfo">
        <div className="cm_15">
          <div className="auto">
            <div className="heading txt_1">{t("topRecommendations")}</div>
            <div className="body">
            <p>{t("portfolioDescription")}</p>
            </div>
            <div className="modf_def_cont_block" 
              // style={{
              //   flexWrap: "wrap",
              //   display: "flex",
              //   flexDirection: "row",
              // }}
            >
              {Array.from({ length: 3 }).map((_, index) => (
                <React.Fragment key={index}>{renderSkeleton()}</React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="aboutinfo">
      <div className="cm_15">
        <div className="auto">
          <div className="heading txt_1">{t("topRecommendations")}</div>
          <div className="body">
            <p>{data?.length > 0 ? t("portfolioDescription") : null}</p>
          </div>
          <div className="modf_def_cont_block"
            // className="row spaced"
            // style={{ flexWrap: "wrap", display: "flex", flexDirection: "row" }}
          >
            {data?.length > 0 ? data.map((item, index) => (
              <div className="c_5" style={{ marginLeft: "0.5vw" }} key={index}>
                <div className="c_slider">
                  <div className="slides propertySerarchSlide">
                    <div className="slides_wrap_info">
                      <Slider {...slideopt1}>
                        {item.elements.urls.map((image, i) => (
                          <div className="link" key={i}>
                            {/* <img
                              className=""
                              alt="not set"
                              title=""
                              src={`${image.url}?w=10&h=5`}			  
                            /> */}
                            <LazyLoadImage
                              alt="property img"
                              src={`${image.url}?w=50&h=50`} // Lower resolution image
                              placeholderSrc={`${item.elements.urls[0].url}?w=50&h=50`}
                              width={380} // Placeholder image
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="row keep spaced short">
                    <div className="location">
                      {item.elements.ort === "" ? "nA" : item.elements.ort}
                    </div>
                    <div>
                      Ref:{" "}
                      {item.elements.objektnr_extern === ""
                        ? "nA"
                        : item.elements.objektnr_extern}
                    </div>
                  </div>
                  <div
                  // href="/propertydetails"
                  // className="link"
                  // title="Link to property"
                  >
                    <div className="heading txt_3" style={{cursor:'pointer'}} onClick={() => handleCardClick(item, index)}>
                      {item.elements.objekttitel === ""
                        ? "Not Set"
                        : item.elements.objekttitel}
                    </div>
                  </div>
                  <div className="price">
                    € {formatPrice(item.elements.kaufpreis)}
                  </div>
                  <div className="row grow keep details">
                    <div className="center_v">
                      <img
                        alt="Schlafzimmer"
                        className="sprites"
                        src={property_pic1}
                      />
                      <div>
                        {item.elements.anzahl_schlafzimmer === ""
                          ? "not set"
                          : parseInt(item.elements.anzahl_schlafzimmer)}
                      </div>
                    </div>
                    <div className="center_v">
                      <img
                        alt="Bäder"
                        className="sprites"
                        src={property_pic2}
                      />
                      <div>
                        {item.elements.anzahl_badezimmer === ""
                          ? "not set"
                          : parseInt(item.elements.anzahl_badezimmer)}
                      </div>
                    </div>
                    <div className="center_v">
                      <img
                        alt="Wohnfläche"
                        className="sprites"
                        src={property_pic3}
                      />
                      <div>
                        {item.elements.wohnflaeche === ""
                          ? "not set"
                          : `${parseInt(item.elements.wohnflaeche)}㎡`}
                      </div>
                    </div>
                    <div className="center_v">
                      <img
                        alt="Grundstück"
                        className="sprites"
                        src={property_pic4}
                      />
                      <div>
                        {item.elements.grundstuecksflaeche === ""
                          ? "not set"
                          : `${parseInt(item.elements.grundstuecksflaeche)}㎡`}
                      </div>
                    </div>
                  </div>
                  <div
                    className="row spaced clicks"
                    style={{ cursor: "pointer", paddingBottom: 15 }}
                    onClick={() => handleCardClick(item, index)}
                  >
                    <div></div>
                    <div className="c_1_1">{t("showEstate")}</div>
                  </div>
                </div>
              </div>
            )): <div style={{heigth:300, display:'flex', alignItems:'center'}}>
            {t('topRecommendationText')}...
            </div>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopRecommendations;
