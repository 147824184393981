import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import useStore from "../../Store/useStore";
import luxusimmobilienadd1 from "../../assets/img/lproperties_pic.jpg";
import PopertiesLike from "../../components/PopertiesLike/PopertiesLike";
import FurtherInformation from "../../components/FurtherInformation/FurtherInformation";
import PopertyDescription from "../../components/PopertyDescription/PopertyDescription";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PropertyDetails = () => {
  const [selectOption, setSelectOption] = React.useState("");
  const setSelectLanguage = useStore((state) => state.setSelectLanguage);
  const { t, i18n } = useTranslation();

  const { lang } = useParams();
  const history = useHistory();
  const location = useLocation();

  const handleChange = (event) => {
    setSelectOption(event.target.value);
  };
  const [page, setPage] = React.useState(1);
  const pagehandleChange = (event, value) => {
    setPage(value);
  };
  //
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * @summary Handling side effect for language change in path parameter
   * @param {[lang]}
   * @returns {void}
   */

  React.useEffect(() => {
    (async () => {
      if (lang) {
        await i18n.changeLanguage(lang);
        setSelectLanguage(lang);
      } else {
        let selectedLang = i18n.language.split("-").at(0);
        let pathWithLang = `${location.pathname.replace(
          "propertydetails",
          `${selectedLang}/propertydetails`
        )}`;
        history.replace(pathWithLang);
      }
    })();
  }, [lang, location]);
  

  return (
    <>
      <div className="inner_cont_wrap">
        <PopertyDescription />
        {/* <FurtherInformation />
      <section className="immobilieVerkaufen nopadding">
        <div className="cm_6">
        <div className="row">
        <div className="pic">
        <img src={luxusimmobilienadd1} alt="" title=""/>
        </div>
        <div className="box">
        <div className="txt_3">
            Luxusimmobilien
        </div>
        <div className="body">
          <p>
              Bei SAE Mallorca erwartet Sie ein exklusives Portfolio an luxuriösen Immobilien in den besten Lagen
          </p>
        </div>
        <a href="#" className="c_1">Jetzt entdecken →</a>
        </div>
        </div>
        </div>
      </section>
      <PopertiesLike /> */}
      </div>
    </>
  );
};

export default PropertyDetails;
